import Highlighter from 'react-highlight-words';

// Interface
interface IHightLighterProps {
  text?: string;
  keyword?: string;
  className?: string;
}

/**
 * @description Highlight the keyword(s) in the text
 * @param text - the input text
 * @param keyword - the keyword(s) to highlight
 * @param className - the class name to apply to the highlighted text
 * @example
 * // some text to be HIGHLIGHTED
 * const text = 'some text pasted from the clipboard';
 *
 * const keyword = 'pasted';
 *
 * // => the word 'pasted' will be highlighted in the text
 */
export const HighLightKeywords = ({ text = '', keyword = '', className = '' }: IHightLighterProps) => (
  <Highlighter
    highlightClassName={className || 'bg-secondary bg-opacity-20 text-ink'}
    searchWords={[keyword]}
    textToHighlight={text}
  />
);

