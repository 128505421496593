export const CvDetailProfileHeadingPlaceholder = () => (
  <div className="mr-2 mt-5 animate-pulse  border-b-2 md:p-5 min-h-screen">

    <div className="flex items-center justify-between">
      <div className="mb-5 h-3 w-1/4 rounded bg-slate-200" />
      <div className="mb-5 h-3 w-1/4 rounded bg-slate-200" />

    </div>
    <div className="mx-auto mb-10 size-40 rounded-full bg-slate-200" />
    <div className="mx-auto px-3">
      <div className="mb-5 h-5 w-1/2 rounded bg-slate-200" />

      <div className="mb-2 h-2 w-full rounded bg-slate-200" />
      <div className="mb-5 h-2 w-full rounded bg-slate-200" />

      <div className="mb-2 h-2 w-1/2 rounded bg-slate-200" />
      <div className="mb-2 h-2 w-1/2 rounded bg-slate-200" />

      <div className="mt-4 flex items-center">
        {[1, 2, 3].map((index) => (
          <div key={index} className="mb-2 mr-2 h-2 w-12 rounded bg-slate-200" />
        ))}
      </div>
    </div>
  </div>
);
