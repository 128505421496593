// RESUME SKILLS SUGGEST  *************************************************************************
// pathname: /v1/resume/skill/suggest
// ************************************************************************************************
import { useSwrApi } from 'src/hooks/useSwrApi';

/**
 * @description Interface for the useSwrOrderContinue hook.
 */
interface UseSwrOrderContinue {
  locale: string,
  orderId?: string | number,
  serverSideData?: ApiSchema<'OrderPayment'>,
  successCallback?: () => void,
}

/**
 * @description SWR: get the order details.
 * - `locale` string - the locale to use for the request.
 * - `orderId` string | number - the order id.
 * - `serverSideData` ApiOrderContinueOk - the server side data.
 * - `successCallback` function - the callback to call after success.
 */
export const useSwrOrderContinue = (props: UseSwrOrderContinue) => {
  // Props destructuring
  const {
    locale,
    orderId,
    serverSideData,
    successCallback,
  } = props;

  // SWR: fetch data
  const {
    data, error, isLoading, isValidating, mutate,
  } = useSwrApi<ApiSchema<'OrderPayment'>>({
    url: `/v1/order/preview-order/${orderId}`,
    locale,
    serverSideData,
    shouldFetch: !!orderId,
    apiOptions: {
      revalidateOnFocus: false,
      dedupingInterval: 1000 * 60, // 1 minute
      onSuccess: () => {
        if (successCallback) successCallback();
      },
    },
    toastsOptions: {
      showErrorToast: true,
    },
  });

  // Return data
  return {
    data,
    error,
    mutate,
    isLoading,
    isValidating,
  };
};
