import { Label, RadioText } from '@components/common/Forms';
import { HandleCheckboxChangeFunction } from 'src/types/hooks-functions/common';


// Component interface
interface CvWorthShareProps {
  handleChange: HandleCheckboxChangeFunction,
  label: string,
}


// The checkbox & text label for sharing Cv-Worth with or without salary
// *******************************
export const CvWorthShare = (props: CvWorthShareProps) => {
  const { handleChange, label } = props;

  return (
    <div className="border-t mt-6 -mb-3 pt-3 text-center">
      <Label htmlFor="shareWithSalary" className="inline-flex justify-center py-2 !mt-0 !w-auto cursor-pointer">
        <input type="checkbox" name="shareWithSalary" onChange={handleChange} />
        <RadioText className="text-sm pt-px">{label}</RadioText>
      </Label>
    </div>
  );
};
