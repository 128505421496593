import { BjThemeColors } from '@type/bj_theme';
import { BadgePill } from '@components/common';
import { useTranslation } from 'next-i18next';


// Applicant status badge
// ******************************************
const applicantStatusBadge = [
  { // new
    statusId: 1,
    color: 'secondary',
    label: 'applicant.status.1',
  },
  { // interview
    statusId: 3,
    color: 'pink',
    label: 'applicant.status.3',
  },
  { // rejected
    statusId: 4,
    color: 'error',
    label: 'applicant.status.4',
  },
  { // shortlist
    statusId: 21,
    color: 'primary-light',
    label: 'applicant.status.21',
  },
  { // hired
    statusId: 22,
    color: 'secondary-light',
    label: 'applicant.status.22',
  },
  { // offer
    statusId: 23,
    color: 'warning',
    label: 'applicant.status.23',
  },
];


/**
 * @description Applicant badge pill component
 * @param statusId - the status id of the applicant (number for new, interview, shortlist, etc.)
 */
export const ApplicantBadgePill = ({ statusId }: { statusId?: number }) => {
  // Find the status by statusId
  const status = statusId ? applicantStatusBadge.find((item) => item.statusId === statusId) : undefined;
  const { t } = useTranslation('common');

  // If status is not found, return null
  if (!status) return null;

  // Return the badge pill
  return (
    <BadgePill
      color={status.color as BjThemeColors}
      size="xxs"
      className="max-w-20 !px-2 text-center font-medium leading-tight"
    >
      <span className="block text-wrap break-all text-pico">{t(status.label)}</span>
    </BadgePill>
  );
};
