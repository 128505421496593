export const CvDetailSkillsPlaceholder = () => (
  <div className="mr-2 animate-pulse border-b-2 py-10">
    <div className="mt-1">
      <div className="mb-2 h-5 w-48 rounded bg-slate-200" />
      <div className="flex">
        {[1, 2, 3, 4, 5].map((index) => (
          <div key={index} className="my-4 mr-4 h-7 w-24 rounded-full border border-slate-200 bg-slate-100" />
        ))}
      </div>
    </div>
  </div>
);
