import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { ViewMoreText } from '@components/common/Text/ViewMoreText';
import { HighLightKeywords } from '@components/common/Text/HighlightKeywords';
import { ChevronUpIcon } from '@heroicons/react/24/solid';
import { AcademicCapIcon } from '@heroicons/react/24/outline';
import { CvDetailObjectWithKeyword, CvDetailStudies } from '../interface/CvDetailInterface';

// SALARY PAGE CONTENT *********************************
export const ProfileStudies = ({ cvDetail, keyword = '' } : CvDetailObjectWithKeyword) => {
  const { t } = useTranslation('common');
  const [numberOfStudies, setNumberOfStudies] = useState<number>(1);
  const [hasAlreadySeenAll, setHasAlreadySeenAll] = useState<boolean>(false);
  const totalNumberOfStudies = cvDetail?.studies?.length ? cvDetail?.studies?.length : 1;

  const showAllExperiences = () => {
    setHasAlreadySeenAll(true);
    setNumberOfStudies(totalNumberOfStudies);
  };

  const showLessExperiences = () => {
    setHasAlreadySeenAll(true);
    setNumberOfStudies(1);
  };

  // get the last index of the experience that contains the keyword to expand the section
  // reverse the original array because we need to get only the last index, and it's nonsense to map all the object
  if (keyword && cvDetail?.studies && !hasAlreadySeenAll) {
    const lastIndex = cvDetail.studies
      .slice()
      .reverse()
      .findIndex((study: CvDetailStudies | undefined) => study?.institution?.toLowerCase()?.includes(keyword?.toLowerCase())
            || study?.description?.toLowerCase()?.includes(keyword?.toLowerCase()));
    // show all the studies if index is greater than the number of studies shown
    if (lastIndex !== -1 && numberOfStudies < totalNumberOfStudies - lastIndex) {
      showAllExperiences();
    }
  }

  return (
    <>
      {cvDetail?.studies
    && (
    <section className="py-7">
      <div className="mb-4">
        <h6 className="mb-4 mr-2 border-b border-dotted pb-4 text-lg font-semibold">
          {t('resume.study.detail')}
        </h6>
      </div>
      <div>

        {cvDetail?.studies.slice(0, numberOfStudies).map((study: CvDetailStudies, index) => (
          <div key={study.id ?? index}>
            <div>
              <div className="flex w-full justify-between py-4">
                <div className="flex w-full items-start">
                  <div>
                    <div
                      className="mr-4 flex min-h-12 min-w-12 items-center justify-center rounded-sm border-2 border-surface-100 bg-surface-100 p-1"
                    >
                      <AcademicCapIcon className="size-6" />
                    </div>
                  </div>
                  <div>
                    <h6 className="py-1.5 text-lg font-semibold">
                      <HighLightKeywords
                        text={study.institution}
                        keyword={keyword}
                      />
                    </h6>

                    <h6 className="text-sm font-normal">
                      <span className="font-semibold text-ink">{study.specialization}</span>

                      <span className="text-ink-medium">
                        {study.specialization && study.studyLevel && `, ${study.studyLevel}`}
                        {!study.specialization && study.studyLevel}
                        {study.location ? `, ${study.location}` : ''}
                      </span>
                    </h6>
                    <div className="flex flex-wrap gap-1.5 text-sm font-normal text-ink-medium">
                      {study.admissionYear && study.graduationYear && (
                      <>
                        <h6>{`${study.admissionYear} ->`}</h6>
                        <h6>{study.graduationYear}</h6>
                        {study.duration && (
                        <>
                          <h6 className="text-ink-medium">•</h6>
                          <h6>{study.duration}</h6>
                        </>
                        )}
                      </>
                      )}
                    </div>


                    {study.description
                            && (
                              <div className="mt-2 text-sm">
                                {keyword.trim() !== '' && study.description.toLowerCase().includes(keyword.toLowerCase()) ? (
                                  <HighLightKeywords
                                    text={study.description}
                                    keyword={keyword}
                                  />
                                ) : (
                                  <ViewMoreText
                                    translated={study.translatedDescription}
                                    description={study.description.replace(/\n/g, '<br>')}
                                  />
                                )}
                              </div>
                            )}


                  </div>
                </div>
              </div>
            </div>
            {index === numberOfStudies - 2 && (
            <div className="border-b border-dotted" />
            )}
          </div>
        ))}

        {cvDetail?.studies.length > numberOfStudies
                && (
                <div
                  role="presentation"
                  className="flex w-fit cursor-pointer items-center rounded-full bg-surface-100 px-4 py-2 font-bold"
                  onClick={showAllExperiences}
                >
                  <span className="block text-base">
                    {`${t('cv.detail.section.more.study')} `}
                    (
                    {Array.isArray(cvDetail?.experiences) && numberOfStudies
                      ? `+${Math.max(cvDetail.studies.length - numberOfStudies, 0)}`
                      : '0'}
                    )
                  </span>
                </div>
                )}

        {cvDetail?.studies.length > 1 && cvDetail?.studies.length === numberOfStudies
          && (
            <div
              className="flex w-fit cursor-pointer items-center rounded-full bg-surface-100 px-4 py-2 font-bold"
              role="presentation"
              onClick={showLessExperiences}
            >
              <ChevronUpIcon className="mr-2 inline-block size-5" />
              <span className="block text-base">
                {`${t('cv.detail.section.less.study')} `}
              </span>
            </div>
          )}

      </div>
    </section>
    )}
    </>
  );
};

