import { useTranslation } from 'next-i18next';
import {
  CvDetailAvailableOperationsCurrentStatus,
  CvDetailBaseOperation,
} from '../interface/CvDetailInterface';
import { getOperationItemTextIcon } from '../utils';

interface IProps {
  operationItem: CvDetailBaseOperation;
  currentCVStatus?: CvDetailAvailableOperationsCurrentStatus;
  withIcon?: boolean;
}

/**
 * @description Component to render the operation item button based on operation and current status
 * @param operationItem
 * @param currentCVStatus
 * @param withIcon
 */
export const ProfileOperationItemButton = ({ operationItem, currentCVStatus, withIcon = true }: IProps) => {
  const { t } = useTranslation('common');

  const textClasses = withIcon ? 'hidden lg:block' : 'block';
  const iconClasses = 'w-5 h-5 mr-0 lg:mr-2';

  // Get the operation item text and icon
  const item = getOperationItemTextIcon(operationItem, currentCVStatus!, t);

  return (
    <>
      {
          withIcon && item.getIcon({ className: iconClasses })
        }
      <span className={textClasses}>
        {item.text}
      </span>
    </>
  );
};
