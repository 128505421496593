// Add rating to a work experience ****************************************************************
// pathname: /v2/talent-rating
// ************************************************************************************************
import {
  GenericV1ErrorsArray,
  ProfileTalentRating,
  useSwrMutationApi,
} from 'src/hooks/useSwrApi';


interface IProps {
  slug: string;
  locale: string;
  successCallback?: () => void;
  errorCallback?: (errors: GenericV1ErrorsArray) => void;
}

/**
 * @description SWR mutation: POST add skill to resume.
 * @param slug - string. The cv details slug.
 * @param locale - string. The locale to use for the request. Default: 'ro'.
 * @param successCallback - function. The callback to call on success.
 * @param successToastText - string. The text to show in the success toast.
 */


export const useSwrResumeTalentRatingAdd = ({
  slug, locale,
  successCallback,
  errorCallback,
}: IProps) => {
  // SWR mutation hook

  const {
    data, error, isMutating, reset, trigger,
  } = useSwrMutationApi<ProfileTalentRating, object>({
    url: `/v2/talent-rating/${slug}`,
    locale,
    fetcherConfig: {
      method: 'POST',
      stringifyPayload: true,
    },
    apiOptions: {
      onSuccess: () => {
        if (successCallback) successCallback();
      },
      onError: (errors) => {
        if (errorCallback) errorCallback(errors);
      },
    },
    toastsOptions: {
      showErrorToast: true,
    },
  });

  // Return data and functions for external use
  return {
    data,
    error,
    isMutating,
    reset,
    trigger,
  };
};
