import { useTranslation } from 'next-i18next';
import { JobFolderSelector, JobFolderSelectorView } from '@components/templates/cv/partials/JobFolderSelector';
import { Button, Modal } from '@components/common';
import { useSwrResumeSetStatus } from '@hooks/useSwrApi/endpoints/resume-operations';
import {
  ApiGetOperationJobOk, ApplicantStatusEnum, UserTalentInterviewFolderItem,
} from '@type/v1-api-types';
import { useState } from 'react';
import { showAddCompanyInfoModal } from '@components/features/modals/employer/AddCompanyInfoModal';
import { useAppSelector } from '@store/index';
import { getUserHeaderInfo } from '@store/reducers/userSelector';

// Interface
// ******************************************
interface SetStatusModalProps {
  isOpen: boolean,
  locale: Locale,
  operationJob: ApiGetOperationJobOk | undefined,
  userSlug: string,
  onClose: (isSuccess: boolean) => void,
  interviewFolders?: UserTalentInterviewFolderItem[],
  status?: ApplicantStatusEnum,
  successToastText?: string,
  title?: string;
  trackingId?: string;
}

/**
 * @description SetStatusModal - Component that can be used to set a status for a talent.
 * * **isOpen** - Whether the modal is opened or not.
 * * **locale** - The locale to use for SWR hooks.
 * * **operationJob** - The selected operation job. Passed down to JobFolderSelector component.
 * * **userSlug** - The user slug.
 * * **onClose** - Callback to execute when the modal is closed.
 * * **interviewFolders** - optional. An array that contains the jobs where the talent already has a status.
 * * **status** - The status to be set. Default to 'shortlist'.
 * * **successToastText** - The text to be displayed in the success toast.
 * The text is already using the translation hook, so you can use the translation key directly.
 * * **title** - The text to be displayed as the modal title. The text is already using the translation hook.
 * * **trackingId** - optional. The tracking id for the event.
 */
export const SetStatusModal = (props: SetStatusModalProps) => {
  // Destructure props
  const {
    isOpen,
    locale,
    onClose,
    operationJob,
    userSlug,
    interviewFolders,
    status = ApplicantStatusEnum.SHORTLIST,
    successToastText = 'talent-card.v2.action.shortlist.toast.success.text',
    title = 'talent-card.v2.action.shortlist.modal.title',
    trackingId,
  } = props;

  // Translation
  const { t } = useTranslation('common');

  const headerInfoData = useAppSelector(getUserHeaderInfo);

  // State
  // *****************************************
  const [jobSelectorView, setJobSelectorView] = useState<JobFolderSelectorView>('default');
  const [selectedOperationJob, setSelectedOperationJob] = useState<ApiGetOperationJobOk | undefined>(operationJob);

  // SWR: Set status
  const {
    trigger,
    isMutating,
  } = useSwrResumeSetStatus({
    userSlug,
    locale,
    status,
    trackingId,
    successCallback: () => {
      onClose(true);
    },
    errorCallback: (errors) => {
      const { code } = errors.errors[0];
      if (code === 403) {
        const successCallbackCompanyInfoAdd = () => {
          void trigger({ jobId: selectedOperationJob?.jobId, title: selectedOperationJob?.title });
        };
        showAddCompanyInfoModal(locale, successCallbackCompanyInfoAdd);
      }
    },
    toastsOptions: {
      successToastText: t(successToastText),
      showErrorToast: headerInfoData?.role === 'COMPANY',
    },
  });

  // Helpers
  // *****************************************
  // Whether the submit button is disabled or not. It can be disabled if at least one of the following conditions is true:
  //  - no operation job is selected
  //  - the action for setting the status is mutating
  //  - the job selector view is in edit / add mode
  //  - the talent has already been saved with this status for the selected operation job
  //
  const isSubmitDisabled = !selectedOperationJob || isMutating || jobSelectorView !== 'default'
    || (!!interviewFolders?.length
      && interviewFolders.some((item) => item.jobId === selectedOperationJob.jobId && item.statusId as ApplicantStatusEnum === status)
    );

  // Render component
  // *****************************************
  return (
    <Modal
      size="sm"
      isOpen={isOpen}
      header={t(title)}
      headerClasses="text-lg"
      onClose={() => onClose(false)}
      className="!overflow-visible"
    >
      <Modal.Body className="!p-8">
        <JobFolderSelector
          selectedJobFolder={selectedOperationJob}
          onChangeView={(selectedView) => setJobSelectorView(selectedView)}
          sendJobFolderInfoToParent={(item) => setSelectedOperationJob(item)}
        />
      </Modal.Body>
      <Modal.Footer className="flex justify-center">
        <Button
          type="button"
          color="secondary"
          rounding="full"
          className="!px-10"
          isLoading={isMutating}
          disabled={isSubmitDisabled}
          onClick={() => { void trigger({ jobId: selectedOperationJob?.jobId, title: selectedOperationJob?.title }); }}
        >
          {t('global.action.save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
