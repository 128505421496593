import { useEffect, useState } from 'react';
import {
  ApiCheckUnlockPay,
  ApplicantStatusEnum,
  GenericV1ErrorsArray,
  UserTalentInterviewFolderItem,
} from '@type/v1-api-types';
import sendGoogleTagEvent from '@hooks/useSendGoogleTagEvent';
import { showUpgradeSubscriptionModal } from '@components/features/modals/employer/subscription/UpgradeSubscriptionModal';
import { useStaticToasts } from '@hooks/useStaticToasts';
import { useSwrResumeCheckUnlock, useSwrResumeGetOperationJob } from '@hooks/useSwrApi/endpoints/resume-operations';
import { useSwrUserResumeDetail } from '@hooks/useSwrApi/endpoints/user';
import { useOpenTokCallStart } from '@components/features/openTokCall/useOpenTokCallStart';
import { useBjModal } from '@hooks/common/useBjModal';
import { showCvContactModal } from '@components/features/modals/employer/CvContactModal';
import { isCheckUnlockStatusPayOk } from 'src/type-predicates/v1-api-predicates/resume-operations';
import { useModalHistory } from '@hooks/history/useModalHistory';
import { showAddCompanyInfoModal } from '@components/features/modals/employer/AddCompanyInfoModal';

// Interface
// **************************************
interface TalentCardActionsProps {
  userSlug: string;
  locale: Locale;
  rid?: string;
  jobSlug?: string;
  jobTitle?: string;
  jobFolderId?: number;
  revalidatePage: (userSlug: string) => void;
  hasInterviewFolder: boolean;
  interviewFolders?: UserTalentInterviewFolderItem[];
  trackingId?: string;
}

/**
 * @description Talent Card Actions hook. Used for actions that are displayed on a talent card.
 * The possible actions are: Send message, Call 1-1, Select for interview.
 * * **userSlug** - The user slug.
 * * **locale** - The locale to use for SWR hooks.
 * * **rid** - optional. Reference ID. Used for tracking purposes.
 * * **jobSlug** - optional. The job slug.
 * * **jobTitle** - optional. The job title.
 * * **jobFolderId** - optional. The job folder id.
 * * **revalidatePage** - Callback function to be called to revalidate SWR Infinite List Data.
 * * **hasInterviewFolder** - Whether the talent has an interview folder or not (the data that comes from server).
 * * **interviewFolders** - optional. An array that contains the jobs where the talent already has the status Selected for interview.
 */
export const useTalentCardActions = (props: TalentCardActionsProps) => {
  // Destructure props
  const {
    userSlug,
    locale,
    rid,
    jobSlug,
    jobTitle,
    jobFolderId,
    revalidatePage,
    hasInterviewFolder,
    interviewFolders,
  } = props;

  const { showStatusToast, statusGenericErrorToast } = useStaticToasts();

  const [canSendMessage, setCanSendMessage] = useState<boolean>(false);
  const { isOpen: displaySendMessageModal, toggleModal: toggleSendMessageModal } = useBjModal();
  const { isOpen: displayOpenCvDetail, toggleModal: toggleDisplayCvDetail } = useBjModal();

  const [unlockedByCall, setUnlockedByCall] = useState <boolean>(false);
  const [unlockedByContactData, setUnlockedByContactData] = useState <boolean>(false);

  const [shouldFetchResumeDetail, setShouldFetchResumeDetail] = useState <boolean>(false);
  const [showVideoOptions, setShowVideoOptions] = useState <boolean>(false);

  // start video call options
  const { onShowVideoOptions } = useOpenTokCallStart();

  const [currentOperationJobId, setCurrentOperationJobId] = useState<number | undefined>(undefined);
  const { isOpen: isOpenStatusModal, toggleModal: toggleStatusModal } = useBjModal();

  const { updateModalHistory } = useModalHistory();

  // Generic error callback for unlock action
  // ******************************************************
  const unlockActionGenericErrorCallback = (errors: GenericV1ErrorsArray | ApiCheckUnlockPay) => {
    if ('errors' in errors) {
      const { code } = errors.errors[0];

      // If the error status code is 601 (not enough credits) we show the upgrade subscription modal.
      // Otherwise, we show a toast with the error message.
      if (code === 601) {
        showUpgradeSubscriptionModal();
      } else if (code !== 701 && code !== 403) {
        showStatusToast(String(`${code}`));
      }
    } else if (isCheckUnlockStatusPayOk(errors)) {
      showCvContactModal(errors?.id, locale);
    }
  };

  // SWR: Get User Resume Detail.
  // ******************************************************
  const {
    data: resumeDetail,
    isLoading: isLoadingResumeDetail,
  } = useSwrUserResumeDetail({
    locale,
    userSlug,
    shouldFetch: shouldFetchResumeDetail,
  });

  // SWR mutation: Unlock for Message
  // ******************************************************
  const {
    trigger: resumeCheckUnlockWithMessageTrigger,
    isMutating: isLoadingSendMessage,
  } = useSwrResumeCheckUnlock({
    userSlug,
    type: 'message',
    locale,
    rid,
    successCallback: () => {
      toggleSendMessageModal();
      if (revalidatePage) { revalidatePage(userSlug); }

      setCanSendMessage(true);

      sendGoogleTagEvent('cv_list_premium_contact_message', 'Custom');
    },
    errorCallback: (errors) => {
      unlockActionGenericErrorCallback(errors);
      const { code } = errors.errors[0];
      if (code === 403) {
        const successCallback = () => {
          void resumeCheckUnlockWithMessageTrigger({
            jobId: jobFolderId,
            title: jobTitle,
            jobSlug,
          });
        };
        showAddCompanyInfoModal(locale, successCallback);
      }
    },
  });

  // SWR mutation: Unlock for contact with call
  // ******************************************************
  const {
    trigger: resumeCheckUnlockByCallTrigger,
    isMutating: isLoadingCallNow,
  } = useSwrResumeCheckUnlock({
    userSlug,
    type: 'contact',
    locale,
    rid,
    successCallback: () => {
      if (revalidatePage) { revalidatePage(userSlug); }
      setUnlockedByCall(true);
      setShouldFetchResumeDetail(true);
      setShowVideoOptions(true);
      sendGoogleTagEvent('cv_list_premium_contact_call', 'Custom');
    },
    errorCallback: (errors) => {
      unlockActionGenericErrorCallback(errors);
      const { code } = errors.errors[0];
      if (code === 403) {
        const successCallback = () => {
          void resumeCheckUnlockByCallTrigger({
            jobId: jobFolderId,
            title: jobTitle,
            jobSlug,
          });
        };
        showAddCompanyInfoModal(locale, successCallback);
      }
    },
  });


  // SWR mutation: Unlock for contact with call
  // ******************************************************
  const {
    trigger: resumeCheckUnlockByContactData,
    isMutating: isLoadingContactData,
  } = useSwrResumeCheckUnlock({
    userSlug,
    type: 'contact',
    locale,
    rid,
    successCallback: () => {
      if (revalidatePage) { revalidatePage(userSlug); }
      updateModalHistory({
        pathname: `/cv/${userSlug}`,
      });
      toggleDisplayCvDetail();
      setUnlockedByContactData(true);
    },
    errorCallback: (errors) => {
      unlockActionGenericErrorCallback(errors);
      const { code } = errors.errors[0];
      if (code === 403) {
        const successCallback = () => {
          void resumeCheckUnlockByContactData({
            jobId: jobFolderId,
            title: jobTitle,
            jobSlug,
          });
        };
        showAddCompanyInfoModal(locale, successCallback);
      }
    },
  });


  // SWR mutation: Get Operation Job
  // ******************************************************
  const {
    data: operationJob,
    trigger: getOperationJobTrigger,
    isMutating: isLoadingOperationJob,
  } = useSwrResumeGetOperationJob({ jobId: currentOperationJobId, locale, successCallback: () => toggleStatusModal() });

  // Unlock with message
  // ******************************************************
  const unlockWithMessage = () => {
    if (!userSlug) {
      statusGenericErrorToast();
      return;
    }

    if (canSendMessage) {
      toggleSendMessageModal();
    } else {
      void resumeCheckUnlockWithMessageTrigger({
        jobId: jobFolderId,
        title: jobTitle,
        jobSlug,
      });
    }
  };

  // Unlock by call
  // ******************************************************
  const unlockByCall = () => {
    if (!userSlug) {
      statusGenericErrorToast();
    }

    if (unlockedByCall) {
      setShowVideoOptions(true);
    } else {
      void resumeCheckUnlockByCallTrigger({
        jobId: jobFolderId,
        title: jobTitle,
        jobSlug,
      });
    }
  };

  // Unlock by contact data
  // ******************************************************
  const unlockByContactData = () => {
    if (!userSlug) {
      statusGenericErrorToast();
    }
    if (unlockedByContactData) {
      toggleDisplayCvDetail();
    } else {
      void resumeCheckUnlockByContactData({
        jobId: jobFolderId,
        title: jobTitle,
        jobSlug,
      });
    }
  };


  useEffect(() => {
    // show video options after unlocking the cv
    // add trackingId to the event if it exists
    if (resumeDetail?.id && unlockedByCall && showVideoOptions) {
      onShowVideoOptions({
        userId: resumeDetail?.id,
        userName: resumeDetail.privateInformation?.name ?? '',
        slug: resumeDetail.slug,
        imgUrl: resumeDetail.imgUrl,
        email: resumeDetail.privateInformation?.email,
        phone: resumeDetail.privateInformation?.phone,
        ...(rid && { trackingId: rid }),
      });
      setShowVideoOptions(false);
    }
  }, [resumeDetail, unlockedByCall, statusGenericErrorToast, onShowVideoOptions, showVideoOptions, rid]);

  // Save to Shortlist action
  // ******************************************************
  const saveToShortlistAction = () => {
    if (hasInterviewFolder && interviewFolders?.length) {
      const displayedFolder = interviewFolders
        .find((item) => (item.statusId as ApplicantStatusEnum) === ApplicantStatusEnum.SHORTLIST);
      if (!displayedFolder) {
        void getOperationJobTrigger();
      } else if (displayedFolder.jobId === operationJob?.jobId) {
        toggleStatusModal();
      } else {
        setCurrentOperationJobId(displayedFolder.jobId);
      }
    } else if (operationJob) {
      toggleStatusModal();
    } else {
      void getOperationJobTrigger();
    }
  };

  useEffect(() => {
    if (currentOperationJobId) {
      void getOperationJobTrigger();
    }
  }, [currentOperationJobId, getOperationJobTrigger]);

  // Return data
  // ********************************
  return {
    canSendMessage,
    displaySendMessageModal,
    toggleSendMessageModal,
    displayOpenCvDetail,
    toggleDisplayCvDetail,
    isLoadingSendMessage,
    unlockWithMessage,
    unlockByCall,
    unlockByContactData,
    isLoadingCallNow,
    isLoadingResumeDetail,
    isLoadingContactData,
    currentOperationJobId,
    setCurrentOperationJobId,
    isOpenStatusModal,
    toggleStatusModal,
    operationJob,
    isLoadingOperationJob,
    saveToShortlistAction,
  };
};
