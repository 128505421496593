import { GithubIcon, LinkedInIcon } from '@components/common/Icons';
import GlobeAltIcon from '@heroicons/react/24/outline/GlobeAltIcon';
import { ReactElement } from 'react';

/**
 * @description Returns social icon by id
 * @param id
 * @param className
 * @constructor
 */
export const ResumeSocialIconById = (id: string, className: string): ReactElement => {
  if (id === 'linkedin') {
    return <LinkedInIcon className={className} />;
  }

  if (id === 'github') {
    return <GithubIcon className={className} />;
  }

  return <GlobeAltIcon className={className} />;
};
