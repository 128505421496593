import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { HighLightKeywords } from '@components/common/Text/HighlightKeywords';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { CvDetailObjectWithKeyword } from '../interface/CvDetailInterface';

export const ProfileAbout = ({ cvDetail, keyword = '' }: CvDetailObjectWithKeyword) => {
  const [collapsedShowMore, setCollapsedShowMore] = useState<boolean>(false);
  const { t } = useTranslation('common');

  // Check if shortDescription exists and has more than 200 characters
  const availableShowMore = (cvDetail?.shortDescription ?? '').length > 200;

  const showMorePreferences = () => {
    setCollapsedShowMore(!collapsedShowMore);
  };

  if (!cvDetail?.shortDescription) return null;

  return (
    <section className="py-7 md:py-3">
      <h6 className="mb-4 mr-2 border-b border-dotted pb-4 text-lg font-semibold">
        {t('cv.detail.professional_wish.section.title')}
      </h6>

      {/* ***** about me description ***** */}
      {cvDetail?.shortDescription && (
        <div className="text-sm">
          <HighLightKeywords
            text={
              !collapsedShowMore && availableShowMore
                ? `${cvDetail.shortDescription.substring(0, 200)}...`
                : cvDetail.shortDescription
            }
            keyword={keyword}
          />
        </div>
      )}

      {/* "See More" Button */}
      {!collapsedShowMore && availableShowMore && (
        <div className="my-2 flex cursor-pointer items-center justify-between" role="presentation" onClick={showMorePreferences}>
          <div className="flex items-center">
            <span className="mr-2 block text-sm text-primary">{t('global.btn.see_more')}</span>
            <ChevronDownIcon className="inline-block size-5 text-primary" />
          </div>
          {cvDetail?.shortDescriptionTranslated && (
            <div className="w-full text-right text-xs">{t('global.label.bia.translated')}</div>
          )}
        </div>
      )}

      {/* "See Less" Button */}
      {collapsedShowMore && availableShowMore && (
        <div className="my-2 flex cursor-pointer items-center" role="presentation" onClick={showMorePreferences}>
          <span className="mr-2 block text-sm text-primary">{t('global.btn.see.less')}</span>
          <ChevronUpIcon className="inline-block size-5 text-primary" />
        </div>
      )}
    </section>
  );
};
