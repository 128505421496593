// ************************************************************************************************
// pathname: /v2/publication/activation-services/{jobSlug}
// ************************************************************************************************
import { useSwrApi } from 'src/hooks/useSwrApi';
import { ApiRequestBody } from '@type/api/apiUtilityFn';

/** Props */
interface UseSwrActivationServicesProps {
  locale: Locale,
  jobSlug?: string,
  serverData?: ApiSchema<'ActivationServices'>,
  payload?: Partial<ApiRequestBody<'/v2/publication/activation-services/{jobSlug}', 'post'>>,
  shouldFetch?: boolean,
  successCallback?: (data?: ApiSchema<'ActivationServices'>) => void,
  onDataChanged?: (data?: ApiSchema<'ActivationServices'>) => void,
}

/**
 * @description SWR: fetch activation-services data
 * - `locale` - the current locale
 * - `jobSlug` - the job slug
 * - `serverData` - server data
 * - `postData` - the post data object
 * - `shouldFetch` - whether to fetch the data; default is true
 * - `successCallback` - success callback function; works only on successful fetch
 * - `onDataChanged` - data changed callback function; works on every data change (from cache or fetch)
 */
export const useSwrActivationServices = (props: UseSwrActivationServicesProps) => {
  const {
    locale,
    jobSlug,
    serverData,
    payload = {},
    shouldFetch = true,
    successCallback,
    onDataChanged,
  } = props;

  return useSwrApi<ApiSchema<'ActivationServices'>>({
    url: `/v2/publication/activation-services${jobSlug ? `/${jobSlug}` : ''}`,
    locale,
    shouldFetch,
    serverSideData: serverData,
    fetcherConfig: {
      method: 'POST',
      stringifyPayload: true,
      payload,
    },
    apiOptions: {
      revalidateOnFocus: false,
      keepPreviousData: true,
      dedupingInterval: 1000 * 60, // 1 minute
      onSuccess: (response) => {
        if (successCallback) successCallback(response);
      },
    },
    onDataChange: (data) => {
      if (onDataChanged) onDataChanged(data);
    },
  });
};
