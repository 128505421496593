import { useEffect, useState } from 'react';
import { useSwrCache } from '@hooks/useSwrApi';
import { SwrCacheGlobalProps } from '@type/swr';
import { getWithFlatKeys } from '@utils/flatten-expand-object/flatten-expand-object';
import { omitFromObject } from '@utils/objects';
import { useModalHistory } from '@hooks/history/useModalHistory';


// Interface
// ******************************************
interface UseInfinitePrevNextProps {
  slug: string,
  data?: unknown,
  itemsFlatKey?: string,
  slugFlatKey?: string,
  swrCacheDataProps?: SwrCacheGlobalProps,
  updateNextHistory?: boolean,
  historyPathname?: string,
  withPageData?: boolean,
}


// Re-export the type for easier access
// ******************************************
export type { SwrCacheGlobalProps } from '@type/swr';


/**
 * @description - This hook is used to get the previous and next items from an infinite list detail page navigation. It also
 * provides the page data of the prev/next items if needed. It's useful for detail pages where you need to navigate through
 * a list of items, like CVs, jobs, etc.
 * **If you don't want to modify browser history, don't pass any historyPathname**.
 *
 * - **slug** - the slug or id of the current detail item. Required.
 * - **data** - the data from the SWR if available. Optional. If not provided, we will use the cached SWR data via *swrCacheDataProps*.
 * - **itemsFlatKey** - the flat key (that means you can use a string with dot notation for nested objects) for the items in the data,
 * say having a page object and the items are in a key called 'items'. If not provided, we will use the data as is.
 * - **slugFlatKey** - the flat key (that means you can use a string with dot notation for nested objects) for the slug or id in the items.
 * If not provided, we will use 'slug' as the default.
 * - **swrCacheDataProps** - the props for the useSwrCache hook. This will be used to get the cached data from SWR.
 * - **updateNextHistory** - if true, it will also update the Next.js routing history. Default is true.
 * - **historyPathname** - the pathname to push to browser history. If not provided, we will not push to browser and Next history. Default is undefined.
 * - **withPageData** - if true, it will also get the page data of the prev/next items. Default is false.
 *
 * The hook returns:
 * - **prevItem** - the previous item from the list. If the current item is the first one, it will look in the previous page.
 * If the current item is the first one in the first page, it will be undefined.
 * - **nextItem** - the next item from the list. If the current item is the last one, it will look in the next page.
 * If the current item is the last one in the last page, it will be undefined.
 * - **lastInList** - a boolean flag to indicate if the current item is the last one in the list. If the current item
 * is the last one in the last page, it will be true. Default is undefined.
 * - **currentItemIndex** - The current item index in the data from the SWR.
 *
 * **IMPORTANT**: Don't forget to pass the *swrCacheDataProps* as query params to the prev/next links, otherwise you will
 * lose the SWR cache context.
 *
 * @example
 * // Usage with cached SWR data
 * // *******************************************
 * // passing the props to a card component
 * <CardInList
 *   swrCacheProps={{
 *     url: '/v3/talent-search',
 *     urlParams: buildSearchParams(queryParams, '', true),
 *     locale,
 *   }}
 * />
 *
 * // Link inside the CardInList for accessing the detail page. Because we're sending the swrCacheProps as query params,
 * // we need to stringify them. If you pass the 'as' prop, the query params will not be shown in the navbar URL.
 * <Link
 *   href={{
 *     pathname: `/cv/${talentSlug}`,
 *     query: { swrCacheProps: swrCacheProps ? JSON.stringify(swrCacheProps) : undefined },
 *   }}
 *   as={`/cv/${talentSlug}`}
 *   prefetch={false}
 * >Link to detail</Link>
 *
 * // Usage in the detail page. Pass an interface if you want to type the data, otherwise it will be unknown.
 * import { useInfinitePrevNext, SwrCacheGlobalProps } from '@hooks/infinite-helpers/useInfinitePrevNext';
 * const { query } = useRouter();
 * const swrCacheProps = typeof query?.swrCacheProps === 'string' ? JSON.parse(query.swrCacheProps) as SwrCacheGlobalProps : undefined;
 * const { prevItem, nextItem } = useInfinitePrevNext<PrevNextInterface>({
 *   slug: slug,
 *   itemsFlatKey: 'items',
 *   swrCacheDataProps: swrCacheProps,
 * });
 *
 * // Usage with data from the page
 * // *******************************************
 * // Pass the data from the page
 * const { prevItem, nextItem } = useInfinitePrevNext<PrevNextInterface>({
 *  slug: slug,
 *  data: dataFromPage,
 *  itemsFlatKey: 'items',
 *  updateHistory: true,
 *  historyPathname: isFromJobContext ? `/job/${jobSlug}/cv/${slug}` : `/cv/${slug}`,
 * });
 *
 * // Also getting the page data of the prev/next items (maybe you need some data from the page object)
 * const { prevItemPage, nextItemPage } = useInfinitePrevNext<PrevNextInterface, PageInterface>({
 *  slug: slug,
 *  data: dataFromPage,
 *  itemsFlatKey: 'items',
 *  withPageData: true,
 * });
 */
export const useInfinitePrevNext = <ItemDataType, PageDataType = unknown>(props: UseInfinitePrevNextProps) => {
  // Destructure props
  const {
    slug,
    data,
    itemsFlatKey,
    slugFlatKey = 'slug',
    swrCacheDataProps,
    historyPathname,
    updateNextHistory,
    withPageData = false,
  } = props;

  // Get the data from the cache. If no cacheDataProps are provided, the cachedData will be undefined.
  const { cachedData } = useSwrCache<ItemDataType>(swrCacheDataProps);


  // Data holders
  // **********************************
  const [prevItem, setPrevItem] = useState<ItemDataType | undefined>(undefined);
  const [nextItem, setNextItem] = useState<ItemDataType | undefined>(undefined);
  const [lastInList, setLastInList] = useState<boolean | undefined>(undefined);
  const [currentItemIndex, setCurrentItemIndex] = useState<number | undefined>(undefined);
  const [prevItemPage, setPrevItemPage] = useState<PageDataType | undefined>(undefined);
  const [nextItemPage, setNextItemPage] = useState<PageDataType | undefined>(undefined);


  // Get the prev/next CV data
  // **********************************
  useEffect(() => {
    // Get the item index and the actual items list if they are from an object key
    const getItemIndex = (usedData: unknown) => {
      if (usedData && Array.isArray(usedData)) {
        const itemsArray: ItemDataType[][] = [];
        const flatItemsArray: ItemDataType[] = [];

        usedData?.forEach((dataGroup) => {
          const currentItems = itemsFlatKey ? getWithFlatKeys(dataGroup as object, itemsFlatKey) as ItemDataType[] : dataGroup as ItemDataType[];
          itemsArray.push(currentItems);
        });

        // flatten the array, then get and save the index
        flatItemsArray.push(...itemsArray.flat());
        const itemIndex = flatItemsArray.findIndex((item) => getWithFlatKeys(item as object, slugFlatKey) === slug);

        return {
          itemIndex: itemIndex >= 0 ? itemIndex : undefined,
          flatItemsArray,
        };
      }

      return {
        itemIndex: undefined,
        flatItemsArray: [],
      };
    };

    // Get the previous and next items
    const getPrevNextItems = ({ itemIndex, flatItemsArray }: { itemIndex: number | undefined, flatItemsArray: ItemDataType[] }) => {
      if (itemIndex !== undefined) {
        setPrevItem(itemIndex > 0 ? flatItemsArray[itemIndex - 1] : undefined);
        setNextItem(itemIndex < flatItemsArray.length - 1 ? flatItemsArray[itemIndex + 1] : undefined);
        setLastInList(itemIndex === flatItemsArray.length - 1);
      } else {
        setPrevItem(undefined);
        setNextItem(undefined);
        setLastInList(undefined);
      }
      setCurrentItemIndex(itemIndex);
    };

    // Set the returned data
    getPrevNextItems(getItemIndex(data || cachedData));
  }, [data, cachedData, itemsFlatKey, slug, slugFlatKey]);


  // Get the prev/next CV page data
  // **********************************
  useEffect(() => {
    // Function to find the page where an item (prevItem or nextItem) is
    const findItemPage = (item: ItemDataType, usedData: unknown) => {
      if (usedData && Array.isArray(usedData)) {
        const pageData = usedData.find((page: PageDataType) => {
          const currentItems = itemsFlatKey ? getWithFlatKeys(page as object, itemsFlatKey) as ItemDataType[] : page as unknown as ItemDataType[];
          return currentItems.find((currentItem) => getWithFlatKeys(currentItem as object, slugFlatKey) === getWithFlatKeys(item as object, slugFlatKey));
        }) as PageDataType;

        // remove the itemsFlatKey from the page data, we don't need it
        return (typeof pageData === 'object') ? omitFromObject(pageData as object, [itemsFlatKey as never]) as PageDataType : pageData;
      }

      return undefined;
    };

    // Set the prev/next page data (without the items though)
    if (withPageData) {
      if (prevItem) setPrevItemPage(findItemPage(prevItem, data || cachedData));
      if (nextItem) setNextItemPage(findItemPage(nextItem, data || cachedData));
    }
  }, [data, cachedData, itemsFlatKey, nextItem, prevItem, slugFlatKey, withPageData]);


  // Handle history in modal
  // **********************************
  useModalHistory({ pathname: historyPathname, updateNextHistory });


  // Return data
  // **********************************
  return {
    prevItem,
    nextItem,
    lastInList,
    currentItemIndex,
    prevItemPage,
    nextItemPage,
  };
};
