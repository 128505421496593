import { useTranslation } from 'next-i18next';
import { ViewMoreText } from '@components/common/Text/ViewMoreText';
import { useState } from 'react';
import { HighLightKeywords } from '@components/common/Text/HighlightKeywords';
import { BullsEyeSolidIcon } from '@components/common/Icons';
import { LinkStyled } from '@components/common';
import { IUserResumeExperience } from '@type/v1-api-types';
import { BriefcaseIcon } from '@heroicons/react/24/outline';
import { CvDetailExperiences, CvDetailObjectWithKeyword } from '../interface/CvDetailInterface';

export const ProfileExperience = ({ cvDetail, keyword = '' } : CvDetailObjectWithKeyword) => {
  const { t } = useTranslation('common');
  const [numberOfExperiences, setNumberOfExperiences] = useState<number>(2);
  const [hasAlreadySeenAll, setHasAlreadySeenAll] = useState<boolean>(false);
  const totalNumberOfExperiences = cvDetail?.experiences?.length ? cvDetail?.experiences?.length : 2;

  const showAllExperiences = () => {
    setHasAlreadySeenAll(true);
    setNumberOfExperiences(totalNumberOfExperiences);
  };

  const showLessExperiences = () => {
    setHasAlreadySeenAll(true);
    setNumberOfExperiences(2);
  };

  // get the last index of the experience that contains the keyword to expand the section
  // reverse the original array because we need to get only the last index, and it's nonsense to map all the object
  if (keyword && cvDetail?.experiences && !hasAlreadySeenAll) {
    const lastIndex = cvDetail.experiences
      .slice()
      .reverse()
      .findIndex((item: CvDetailExperiences | undefined) => (
        (item?.company?.toLowerCase()?.includes(keyword?.toLowerCase())
          || item?.title?.toLowerCase()?.includes(keyword?.toLowerCase())
          || item?.description?.toLowerCase()?.includes(keyword?.toLowerCase()))
      ));

    if (lastIndex !== -1 && numberOfExperiences < totalNumberOfExperiences - lastIndex) {
      showAllExperiences();
    }
  }


  const getExperienceLogo = (experience: IUserResumeExperience) => (
    experience.workplaceLogo
      ? (
        <div
          className="mr-4 min-h-12 min-w-12 rounded-sm border-2 border-surface-100 bg-contain bg-center bg-no-repeat p-1"
          style={{ backgroundImage: `url(${experience.workplaceLogo})` }}
        />
      )
      : (
        <div
          className="mr-4 flex min-h-12 min-w-12 items-center justify-center rounded-sm border-2 border-surface-100 bg-surface-100 p-1"
        >
          <BriefcaseIcon className="size-6" />
        </div>
      )
  );

  return (
    <>
      {cvDetail?.experiences && (
        <section id="experience">
          <div className="mb-4 mr-2 border-b border-dotted pb-4 text-lg font-semibold">
            <h6>
              {t('cv.section.label.professional_experience')}
            </h6>
            {cvDetail?.experienceSummary && <span className="block text-sm font-light text-ink-medium">{cvDetail?.experienceSummary?.duration}</span>}
          </div>

          <div>
            {cvDetail.experiences.slice(0, numberOfExperiences).map((experience: CvDetailExperiences, index) => (
              <div key={experience.id ?? index}>
                <div>
                  <div className="flex w-full justify-between py-4">
                    <div className="flex w-full items-start">

                      {/* Company Logo */}
                      {getExperienceLogo(experience)}
                      <div>
                        {cvDetail?.leadExperience?.id === experience.id
                          && (
                            <div className="relative  flex justify-start">
                              <span className="flex items-center text-sm font-semibold text-secondary">
                                <BullsEyeSolidIcon className="mr-2 size-3.5 shrink-0" />
                                {t('label-cv-view-lead-experience')}
                              </span>
                            </div>
                          )}


                        {experience.title && <h6 className="py-1.5 text-lg font-semibold">{experience.title}</h6>}

                        <div className="flex flex-col gap-1.5 text-sm">

                          <div className="flex flex-wrap gap-1 text-base text-ink-medium">
                            {/* ***** company name ***** */}
                            {experience.workplaceName ? (
                              <>
                                {experience.workplaceSlug ? (
                                  <LinkStyled
                                    href={`/company-profile/${experience.workplaceSlug}`}
                                    target="_blank"
                                    styling="none"
                                    className="min-w-fit font-normal underline"
                                  >
                                    {experience.workplaceName}
                                  </LinkStyled>
                                ) : (
                                  <div className="min-w-fit font-bold text-ink">
                                    <HighLightKeywords text={experience.workplaceName} keyword={keyword} />
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                {experience.company && (
                                  <div className="min-w-fit font-bold text-ink">
                                    <HighLightKeywords text={experience.company} keyword={keyword} />
                                  </div>
                                )}
                              </>
                            )}


                            {!experience.workplaceName && experience.company}

                            {/* location */}
                            {
                              experience.location && experience.location.length > 0 && (
                                <>
                                  <h6>{t('global.label.in')}</h6>
                                  <h6 className="font-normal text-ink-medium">{experience.location}</h6>
                                </>
                              )
                            }
                          </div>

                          <div className="flex flex-wrap gap-1.5 text-sm text-ink-medium">
                            {/* start and end */}
                            <>
                              <h6>{`${experience.start ? `${experience.start} ` : ''}->`}</h6>
                              <h6>
                                {experience.end ?? ''}
                              </h6>
                              {experience.period && (
                                <>
                                  <h6>•</h6>
                                  <h6>{experience.period}</h6>
                                </>
                              )}
                            </>

                            {/* salary */}
                            {experience.salary && (
                              <h6 className="text-ink-medium">{`•  ${experience.salary}`}</h6>
                            )}

                          </div>


                          {keyword.trim() !== '' && experience.description.toLowerCase().includes(keyword.toLowerCase()) ? (
                            <HighLightKeywords
                              text={experience.description}
                              keyword={keyword}
                            />
                          ) : (
                            <ViewMoreText translated={experience.translated} description={experience.description} />
                          )}
                        </div>

                      </div>
                    </div>
                  </div>

                  {index === numberOfExperiences - 2 && (
                    <div className="border-b border-dotted" />
                  )}
                </div>
              </div>
            ))}


            {cvDetail?.experiences.length > numberOfExperiences
              && (
                <div
                  className="flex w-fit cursor-pointer items-center rounded-full bg-surface-100 px-4 py-2 font-bold"
                  role="presentation"
                  onClick={showAllExperiences}
                >
                  <span className="block text-base">
                    {`${t('cv.detail.section.more.experience')} `}
                    (
                    {typeof cvDetail?.experiences?.length === 'number' && numberOfExperiences
                      ? `+${Math.max(cvDetail.experiences.length - numberOfExperiences, 0)}`
                      : '0'}
                    )
                  </span>
                </div>
              )}

            {cvDetail?.experiences.length === numberOfExperiences && hasAlreadySeenAll
              && (
                <div
                  className="flex w-fit cursor-pointer items-center rounded-full bg-surface-100 px-4 py-2 font-bold"
                  role="presentation"
                  onClick={showLessExperiences}
                >
                  <span className="block text-base">
                    {`${t('cv.detail.section.less.experience')} `}
                  </span>
                </div>
              )}

          </div>
        </section>
      )}
    </>
  );
};
