// TAG CLOUD **************************************************************************************
// pathname: /v1/tag-cloud
// ************************************************************************************************
import { useSwrApi } from 'src/hooks/useSwrApi';
import { ApiTagCloudOk } from '@type/v1-api-types';


// SWR: fetch tag cloud
// usage: const { data, error, ... } = useSwrTagCloud(locale);
// ********************************************
export const useSwrTagCloud = (locale: string) => {
  // SWR: fetch data
  const {
    data, error, isLoading, isValidating, mutate,
  } = useSwrApi<ApiTagCloudOk>({
    url: '/v1/tag-cloud',
    locale,
    apiOptions: {
      revalidateOnFocus: false,
      dedupingInterval: 1000 * 60 * 5, // 5 minutes
    },
  });

  // Return data
  return {
    data,
    error,
    mutate,
    isLoading,
    isValidating,
  };
};
