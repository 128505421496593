import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '@store/index';
import { updateApplicantTalentList } from '@store/reducers/employer/lists/listsReducer';
import { getApplicantTalentList } from '@store/reducers/employer/lists/listsSelector';


// Interface
// ************************************
interface UseApplicantListsRedux {
  statusChanged?: boolean,
  lockedChanged?: boolean,
  slug?: string,
  statusId?: number,
}


/**
 * @description Helper hook for updating Redux when in a modal and the CV was unlocked or status (rejected, interview, shortlist, etc.) was changed.
 * Used for updating the infinite lists and know when to trigger data mutations.
 *
 * - **statusChanged** - boolean - If the status has changed, optional.
 * - **lockedChanged** - boolean - If the locked state has changed, optional.
 * - **slug** - string - The slug of the CV, optional.
 * - **statusId** - number - The status ID, optional.
 *
 * @example
 * // Example 1: Use the hook with hardcoded props.
 * useApplicantListsRedux({
 *  statusChanged: true,
 *  lockedChanged: false,
 *  slug: 'cv-slug',
 *  statusId: 1,
 * });
 *
 * // Example 2: Use the hook with dynamic props.
 * useApplicantListsRedux({ statusChanged, lockedChanged, slug, statusId });
 */
export const useApplicantListsRedux = (props: UseApplicantListsRedux) => {
  // Destructure props
  const {
    statusChanged,
    lockedChanged,
    slug = '',
    statusId,
  } = props;


  // Redux states for infinite lists. When in modal, with this we watch if:
  // - The applicant/talent status has changed (interview, shortlist, etc.).
  // - The applicant/talent has been contacted/unlocked (message, show contact details).
  // We will mutate the infinite list according to these states.
  // *************************************
  const dispatch = useAppDispatch();
  const listState = useAppSelector(getApplicantTalentList);


  // Handle redux updates for infinite lists.
  // Check if we're in the modal context or not (when modal is opened, listState will be initialized).
  // We do nothing if it's not.
  // *************************************
  useEffect(() => {
    if (listState) {
      const statusSlugsArray = new Set(listState?.statusChangedSlugs || []);
      const statusIdsArray = new Set(listState?.statusChangedIds || []);
      const unlockedSlugsArray = new Set(listState?.unlockedSlugs || []);

      // Add the slug to the Status Changed list
      if (statusChanged && !statusSlugsArray.has(slug)) {
        statusSlugsArray.add(slug);
        if (statusId) statusIdsArray.add(statusId);
        dispatch(updateApplicantTalentList({
          ...listState,
          statusChangedSlugs: [...statusSlugsArray],
          ...(statusId && { statusChangedIds: [...statusIdsArray] }),
        }));
      }

      // Add the slug to the Unlocked list.
      if (lockedChanged && !unlockedSlugsArray.has(slug)) {
        unlockedSlugsArray.add(slug);
        dispatch(updateApplicantTalentList({
          ...listState,
          unlockedSlugs: [...unlockedSlugsArray],
        }));
      }
    }
  }, [dispatch, listState, slug, statusChanged, lockedChanged, statusId]);
};
