import React from 'react';
import { useTranslation } from 'next-i18next';
import {
  CvDetailInterface,
} from '../interface/CvDetailInterface';


export const ProfileInterview = ({ cvDetail }: CvDetailInterface) => {
  const { t } = useTranslation('common');

  return (
    <>
      { cvDetail?.application?.miniInterview
          && <h3 className="text-lg font-bold mb-3">{t('cv.detail.mini_interview')}</h3>}
      { cvDetail?.application?.miniInterview && cvDetail?.application?.miniInterview.map((item:{ question:string;answer:string }) => (
        <div key={item.question} className="border-b mb-4">
          <div className="font-bold text-sm mb-2">{item.question}</div>
          <blockquote className="ml-1 pl-4 my-4 border-l-4 border-gray-300">
            <div className="font-normal text-sm my-3">{item.answer}</div>
          </blockquote>
        </div>
      ))}
    </>
  );
};
