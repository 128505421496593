// Evaluate cv from job detail **************************************************************************
// pathname: /v1/resume//evaluate/{$evaluationId}/feedback
// method:   POST
// ************************************************************************************************
import { GenericV1ErrorsArray, useSwrMutationApi } from '@hooks/useSwrApi';

/**
 * @description SWR mutation: POST to get AI evaluation feedback for profile. Response status ok: 200.
 * @param evaluationId - string. The evaluation identifier.
 * @param isAutoEvaluation - boolean. If the evaluation is auto evaluation.
 * @param successCallback - function. The callback to call on success.
 * @param errorCallback - function. The callback to call on error.
 */
export const useSwrBiaResumeEvaluationFeedback = (
  evaluationId: string,
  isAutoEvaluation: boolean,
  successCallback?: () => void,
  errorCallback?: (errors: GenericV1ErrorsArray) => void,
) => {
  const link = isAutoEvaluation
    ? `/v1/resume/evaluate/${evaluationId}/feedback`
    : `/v1/job/${evaluationId}/evaluation/feedback`;

  // SWR mutation
  // ********************************************
  const {
    data, error, isMutating, reset, trigger,
  } = useSwrMutationApi<object, ApiSchema<'EvaluationFeedbackWrite'>>({
    url: link,
    fetcherConfig: {
      method: 'POST',
      stringifyPayload: true,
    },
    apiOptions: {
      onSuccess: () => {
        if (successCallback) successCallback();
      },
      onError: (errors) => {
        if (errorCallback) errorCallback(errors);
      },
    },
    toastsOptions: {
      showSuccessToast: true,
      showErrorToast: true,
    },
  });

  // Return data
  // ********************************************
  return {
    data,
    error,
    isMutating,
    reset,
    trigger,
  };
};
