import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useTranslation } from 'next-i18next';
import { Modal } from '@components/common/Modal/Modal';
import { CustomerForm } from '@components/templates/company/billing/CustomerForm';
import { Button, Input, Label } from '@components/common/Forms';
import { CardPaymentList } from '@components/features/payment';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useSwrOrderContinue } from '@hooks/useSwrApi/endpoints/order/useSwrOrderContinue';
import { useEffect, useState } from 'react';
import { useBjForm } from '@hooks/useBjForm';
import { ApiOrderCheckoutOk } from '@utils/data-fetching';
import { useRouter } from 'next/router';
import { useSwrOrderDeleteCart } from '@hooks/useSwrApi/endpoints/order/useSwrOrderDeleteCart';
import { useSwrMutationOrderUpdateCart } from '@hooks/useSwrApi/endpoints/order/useSwrOrderUpdateCart';

interface ContinueOrderProps {
  locale: Locale,
  shoppingCartId: string,
}

/**
 * @description Renders the cv unlock payment modal
 *
 * The modal can be opened anywhere in the app using the **showCvContactModal** function.
 */
export const CvContactModal = NiceModal.create((props: ContinueOrderProps) => {
  const { shoppingCartId, locale } = props;
  // Use a hook to manage the modal state
  const modal = useModal();

  // Close modal
  const closeModal = () => {
    void modal.hide();
  };

  const { data: initialCartData } = useSwrOrderContinue({
    locale,
    orderId: shoppingCartId,
  });

  const [
    cartData, setCartData,
  ] = useState<ApiSchema<'OrderPayment'>>();

  useEffect(() => {
    setCartData(initialCartData);
  }, [initialCartData]);

  // Translation
  const { t } = useTranslation('common');

  // Bj form hook
  // *************************************
  const {
    record, formStatus, handleSubmit,
  } = useBjForm({
    submitURL: `/v1/order/save-order/${shoppingCartId}`,
    submitMethod: 'POST',
  });

  // 1. State used to get the selected payment method from the CardPaymentList
  // *****************************************
  const [
    selectedPaymentMethod, setSelectedPaymentMethod,
  ] = useState<ApiSchema<'PaymentTypeModel'>>();

  const [
    selectedCard, setSelectedCard,
  ] = useState(0);

  useEffect(() => {
    setSelectedPaymentMethod(
      cartData?.paymentTypes ? cartData?.paymentTypes[0] : undefined,
    );
    setSelectedCard(
      Number(cartData?.creditCards?.filter((card) => card.recurrent)[0]?.id),
    );
  }, [cartData]);

  const router = useRouter();

  useEffect(() => {
    if (formStatus.actionStatus === 'success') {
      const response = formStatus.data as ApiOrderCheckoutOk;
      if (response && Object.keys(response).length > 0) {
        if (response?.checkoutSessionUrl) {
          window.location.replace(response?.checkoutSessionUrl);
        }
        if (response.completed) void modal.hide();
      }
    }
  }, [formStatus, modal]);

  const successCallback = () => {
    void router.push('/company/offer');
  };

  // SWR: Delete comment
  // ******************************************************
  const {
    trigger: emptyShoppingCart,
    isMutating: isDeletingCart,
  } = useSwrOrderDeleteCart({ locale, shoppingCartId, successCallback });

  const initialCreditsAmount = cartData?.order?.amount || 0;

  const { data: newCartData, trigger } = useSwrMutationOrderUpdateCart({ locale, orderId: shoppingCartId });

  useEffect(() => {
    if (newCartData) setCartData(newCartData);
  }, [newCartData]);

  const minValue = 1;
  const maxValue = 200000;
  const getCreditsAmount = (value: number) => {
    void trigger({ creditAmount: value });
  };

  // Render component
  // *****************************************
  return (
    <Modal
      isOpen={modal.visible}
      onClose={closeModal}
      size="lg"
      header={t('global.action.order')}
    >
      <Modal.Body>
        {cartData && (
        <form onSubmit={handleSubmit}>
          <h1 className="flex justify-center text-xl text-secondary">
            <strong>{t(t('global.action.order'))}</strong>
          </h1>
          <div className="mb-5 flex justify-center">
            <Label
              className="!w-auto text-center"
              htmlFor="creditAmount"
              descriptionClass="font-bold text-ink-medium w-auto !text-lg mb-3"
              description={t('user.order.creditbox.title')}
              required
            >
              {/* input element */}
              <Input
                className="flex w-auto !min-w-[250px]"
                type="number"
                min={minValue}
                max={maxValue}
                onChange={(e) => getCreditsAmount(Number(e.target.value))}
                {...record('amount', { convertEmptyValue: 'toNumber', defaultValue: initialCreditsAmount })}
              />

              <p className="mt-3 text-lg font-bold text-ink-medium">
                {cartData?.order?.totalPrice}
                {' '}
                {cartData?.order?.currency}
              </p>
            </Label>
          </div>
          <CustomerForm customer={cartData?.customer} locale={locale} record={record} />
          <Input
            type="hidden"
            value={selectedCard || 0}
            {...record('creditCardId', { convertEmptyValue: 'toNumber' })}
          />
          <Input
            type="hidden"
            value={selectedPaymentMethod?.id || 0}
            {...record('paymentTypeId', {})}
          />

          {/* Payment methods list */}
          <CardPaymentList
            className="my-7"
            paymentMethods={cartData?.paymentTypes}
            creditsAmount={cartData?.availableCredits}
            creditCards={cartData?.creditCards}
            getSelectedMethod={setSelectedPaymentMethod}
            getSelectedCard={setSelectedCard}
            completedProfile={cartData?.customer?.vatNumber !== ''}
          />
          <div className="justify-between text-center md:flex md:text-start">
            <Button
              styling="text"
              size="sm"
              onClick={() => {
                void emptyShoppingCart({ data: shoppingCartId });
              }}
              isLoading={isDeletingCart}
              className="mb-5"
            >
              <TrashIcon
                className="mr-2 size-5"
              />
              {t('order.bt.cancel')}
            </Button>
            <div>
              <span className="mr-2 text-sm font-bold text-surface-300">
                {t('global.label.total.only')}
                :
              </span>
              <span className="mr-3 text-lg font-bold text-secondary">
                {cartData?.order?.totalPrice}
                {' '}
                {cartData?.order?.currency}
              </span>
              <Button
                size="sm"
                color="secondary"
                rounding="full"
                className="!px-7"
                type="submit"
              >
                {t('order.choosePayment.cta')}
              </Button>
            </div>
          </div>

        </form>
        )}

      </Modal.Body>
    </Modal>
  );
});


/**
 * @description Opens the cv unlock payment modal anywhere in the app
 *
 * @example
 * // import the function
 * import { showCvContactModal } from '@components/features/modals/employer/CvContactModal';
 * // usage
 * <Button onClick={showCvContactModal(shoppingCardId, locale)}>Show contact modal</Button>
 */
export const showCvContactModal = (id: string, locale: Locale) => {
  void NiceModal.show(CvContactModal, { shoppingCartId: id, locale });
};
