import Router from 'next/router';
import { useEffect, useCallback } from 'react';


// Interface
// ************************************
interface UseModalHistoryProps {
  pathname?: string,
  fullUrl?: string,
  updateNextHistory?: boolean,
}


/**
 * @description Update the browser and next.js routing history. Used when viewing the detail pages in a modal, and you
 * need to update the URL. If you provide the parameters, the hook will update the browser and next.js routing history
 * automatically. Otherwise, you can call the returned function manually.
 *
 * ++ Props ++
 * - **pathname** - string - The pathname to update. You don't need to provide the full URL, just the pathname.
 * - **fullUrl** - string - The full URL to update. If you provide this, the hook will use this instead of the pathname.
 * - **updateNextHistory** - boolean - If the next.js history should be updated, optional.
 *
 * **Return**
 * - **updateModalHistory** - function - The function to update the browser and next.js routing history manually (ie, on closing a modal).
 *
 */
export const useModalHistory = (props?: UseModalHistoryProps) => {
  // Destructure props
  const {
    pathname,
    fullUrl,
    updateNextHistory = true,
  } = props || {};


  // Next.js and browser routing history
  // **********************************
  const updateNextJsHistory = useCallback(async (url: string) => Router.replace(url, undefined, { shallow: true }), []);


  // Update the browser history and URL manually. Same parameters as the hook.
  // **********************************
  const updateModalHistory = useCallback((params: UseModalHistoryProps) => {
    const {
      pathname: path,
      fullUrl: url,
      updateNextHistory: updateNext,
    } = params;

    if (url || path) {
      const domainUrl = window.location.origin;
      const composedUrl = url || `${domainUrl}${path}`;

      // Push to browser and next.js history
      window.history.pushState({ path: composedUrl }, '', composedUrl);
      if (updateNext) void updateNextJsHistory(composedUrl);
    }
  }, [updateNextJsHistory]);


  // Update the browser history and URL automatically if the pathname or fullUrl is provided.
  // **********************************
  useEffect(() => {
    const timer = pathname || fullUrl
      ? setTimeout(() => {
        updateModalHistory({ pathname, fullUrl, updateNextHistory });
      }, 200)
      : undefined;

    return () => {
      if (pathname || fullUrl) clearTimeout(timer);
    };
  }, [pathname, fullUrl, updateNextHistory, updateModalHistory]);


  // Return
  // **********************************
  return {
    updateModalHistory,
  };
};
