import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'next-i18next';
import { Avatar } from '@components/common';
import {
  CvDetailInterface,
} from '../interface/CvDetailInterface';


export const ProfileAgentData = ({ cvDetail }: CvDetailInterface) => {
  const { t } = useTranslation('common');


  return (
    <>
      <div className="mt-3 bg-secondary bg-opacity-10 px-5 py-3">

        <div className="flex items-center">
          {cvDetail?.agent?.imgUrl && (
            <Avatar
              imgPath={cvDetail.agent.imgUrl}
              imgFolder="resume_image"
              extractImageName
              alt="Recomennded agent profile"
            />
          )}


          <div className="px-4">
            <p className="mb-0 text-xs font-normal">{t('candidate.recommended.by')}</p>
            {cvDetail?.agent?.name
                  && (
                  <h2 className="mb-1 text-lg font-bold">
                    {cvDetail.agent.name}
                  </h2>
                  )}

            {cvDetail?.agent?.phone
                  && (
                  <div className="mb-2 flex items-center text-primary">
                    <EnvelopeIcon className="mr-2 w-4" />
                    <a href={`tel:${cvDetail.agent.phone}`} className="inline-block text-xs font-normal text-primary">
                      {cvDetail.agent.phone}
                    </a>
                  </div>
                  )}

            {cvDetail?.agent?.email
                  && (
                  <div className="flex items-center text-primary">
                    <PhoneIcon className="mr-2 w-4" />
                    <a href={`mailto:${cvDetail?.agent.email}`} className="inline-block text-xs font-normal text-primary">
                      {cvDetail?.agent.email}
                    </a>
                  </div>
                  )}
          </div>

        </div>
      </div>
    </>
  );
};
