import { useMemo } from 'react';
import {
  FacebookIcon, LinkedInIcon, TwitterIcon, WhatsAppIcon,
} from '@components/common/Icons/Brands';
import { EnvelopeIcon } from '@heroicons/react/24/solid';
import { Anchor } from '@components/common/Anchor/Anchor';


// Component interface
// *******************************
export interface SocialMediaShareListProps {
  shareUrl: string,
  hashtag?: {
    facebook?: string,
    twitter?: string,
    linkedin?: string,
    whatsapp?: string,
  },
  ogTitle: string,
  ogDescription: string,
}


// Base classes for social media share buttons
const baseButtonsClasses = 'w-10 h-10 rounded-full flex items-center justify-center text-surface hover:shadow-md';
const baseIconClasses = 'w-5 h-5 text-white';

/**
 * @description Social media share button list.
 *
 * @example
 * <SocialMediaShareList
 *    shareUrl="https://www.google.com"
 *    hashtag={{
 *      facebook: '%23bestjobs',
 *      twitter: '%23bestjobs',
 *      linkedin: '%23bestjobs',
 *      whatsapp: '%23bestjobs',
 *    }}
 *    ogTitle="BestJobs - The best jobs in the world"
 *    ogDescription="BestJobs is the best job board in the world"
 * />
 */
export const SocialMediaShareList = (props: SocialMediaShareListProps) => {
  // Component props destructuring
  const {
    shareUrl, hashtag, ogTitle, ogDescription,
  } = props;

  // Memoize the list of social media to avoid re-rendering
  const socialMediaList = useMemo(() => ({
    facebook: {
      icon: <FacebookIcon className={baseIconClasses} />,
      url: `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}${hashtag?.facebook ? `&hashtag=${hashtag.facebook}` : ''}`,
      className: 'bg-social-facebook hover:bg-social-facebook/80',
    },
    linkedin: {
      icon: <LinkedInIcon className={baseIconClasses} />,
      url: `https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}${hashtag?.linkedin ? `&hashtag=${hashtag.linkedin}` : ''}`,
      className: 'bg-social-linkedin hover:bg-social-linkedin/80',
    },
    twitter: {
      icon: <TwitterIcon className={baseIconClasses} />,
      url: `https://twitter.com/share?text=${shareUrl}${hashtag?.twitter ? `&hashtags=${hashtag.twitter}` : ''}`,
      className: 'bg-social-twitter hover:bg-social-twitter/80',
    },
    whatsapp: {
      icon: <WhatsAppIcon className={baseIconClasses} />,
      url: `https://api.whatsapp.com/send?text=${shareUrl}${hashtag?.whatsapp ? `&hashtags=${hashtag.whatsapp}` : ''}`,
      className: 'bg-social-whatsapp hover:bg-social-whatsapp/80',
    },
    email: {
      icon: <EnvelopeIcon className={`${baseIconClasses} !text-surface`} />,
      url: `mailto:?subject=${ogTitle}&body=${ogDescription}%0D%0A${shareUrl}`,
      className: 'bg-ink hover:bg-ink/80',
    },
  }), [shareUrl, hashtag, ogTitle, ogDescription]);

  // Render component
  return (
    <>
      {
        Object.entries(socialMediaList).map((entry) => {
          const site = entry[0];
          const { icon, url, className } = entry[1];

          return (
            <Anchor href={url} styling="none" target="_blank" rel="noreferrer" className={`${baseButtonsClasses} ${className}`} aria-label={site} key={site}>
              <>{ icon }</>
            </Anchor>
          );
        })
      }
    </>
  );
};
