import { HandThumbDownIcon, HandThumbUpIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'next-i18next';
import { CustomBiaEvaluate } from '@components/common/Icons';
import { CvDetailInterface } from '@components/templates/cv/interface/CvDetailInterface';
import { AiEvaluationFeedbackModal } from '@components/features/modals/ai-evaluation-feedback/AiEvaluationFeedbackModal';
import { FlagIcon } from '@heroicons/react/24/outline';
import { useBjModal } from '@hooks/common/useBjModal';

/**
 * @description Component to render the comments section.
 * @param cvDetail - the cv detail object
 */
export const EvaluateBia = ({ cvDetail }: CvDetailInterface) => {
  // Translation
  const { t } = useTranslation('common');

  const renderIconByScore = (score: number) => {
    if (score >= 0 && score <= 50) {
      return <HandThumbDownIcon className="size-5 min-w-5 text-error" />;
    }
    if (score > 50 && score <= 69) {
      return <HandThumbUpIcon className="size-5 min-w-5 text-warning" />;
    }
    return <HandThumbUpIcon className="size-5 min-w-5 text-secondary" />;
  };

  const { isOpen: feedbackModal, toggleModal: toggleFeedbackModal } = useBjModal();


  // Render component
  // ******************************************************
  return (
    <>
      {/* Added bia evaluation on cv detail, if exist */}
      {
        cvDetail?.application?.evaluation && (
          <div id="evaluateBia">
            <div className="flex items-center justify-between py-3 text-sm">
              <div className="flex w-full items-center">
                <div className="w-full text-ink">{t('bia.evaluate.text')}</div>
                <CustomBiaEvaluate className="min-w-15" />
              </div>
            </div>

            <div className="rounded-md bg-surface-100 p-2">

              <div className="mb-5 flex items-start gap-2 rounded-md p-2">
                {renderIconByScore(cvDetail?.application?.evaluation?.score ?? 0)}
                <div className="text-tiny font-thin">
                  {cvDetail?.application?.evaluation?.summary}
                </div>
              </div>

              {cvDetail?.application?.evaluation && cvDetail?.application?.applicantId && (
                <div className="mb-5 flex justify-center">
                  <AiEvaluationFeedbackModal
                    isOpen={feedbackModal}
                    toggleModal={toggleFeedbackModal}
                    identifier={cvDetail?.application?.applicantId.toString()}
                    applicantEvaluation={cvDetail?.application?.evaluation}
                  />
                  <div className="flex cursor-pointer justify-center text-sm text-primary" role="presentation" onClick={toggleFeedbackModal}>
                    <FlagIcon className="mr-2 inline size-6" />
                    {t('bia.evaluate.feedback')}
                  </div>
                </div>
              )}

              <div className="mb-3 text-sm">
                <p className="font-bold">
                  {`${t('bia.evaluate.overall.score')} `}
                  <span className="font-normal">
                    {Math.ceil(cvDetail?.application?.evaluation?.score ?? 0)}
                    /100
                  </span>
                </p>
              </div>
              <div className="mb-3 text-sm">
                <p className="font-bold">{`${t('bia.evaluate.scores')} `}</p>
                <ul className="list-disc pl-5">
                  <li>
                    <p className="text-xs font-bold">
                      {`${t('bia.evaluate.scores.skills')} `}
                      <span className="font-normal">
                        {Math.ceil(cvDetail?.application?.evaluation?.skillsMatchScore ?? 0)}
                        /100
                      </span>
                    </p>
                    <p className="text-xs">
                      {cvDetail?.application?.evaluation?.skillsMatchExplanation}
                    </p>
                  </li>
                  {cvDetail?.application?.evaluation?.experienceMatchExplanation && (
                  <li>
                    <p className="text-xs font-bold">
                      {`${t('bia.evaluate.scores.experience')} `}
                      <span className="font-normal">
                        {Math.ceil(cvDetail?.application?.evaluation?.experienceMatchScore ?? 0)}
                        /100
                      </span>
                    </p>
                    <p className="text-xs">
                      {cvDetail?.application?.evaluation?.experienceMatchExplanation}
                    </p>
                  </li>
                  )}
                  <li>
                    <p className="text-xs font-bold">
                      {`${t('bia.evaluate.scores.education')} `}
                      <span className="font-normal">
                        {Math.ceil(cvDetail?.application?.evaluation?.educationMatchScore ?? 0)}
                        /100
                      </span>
                    </p>
                    <p className="text-xs">
                      {cvDetail?.application?.evaluation?.educationMatchExplanation}
                    </p>
                  </li>
                  <li>
                    <p className="text-xs font-bold">
                      {`${t('bia.evaluate.scores.location')} `}
                      <span className="font-normal">
                        {Math.ceil(cvDetail?.application?.evaluation?.locationMatchScore ?? 0)}
                        /100
                      </span>
                    </p>
                    <p className="text-xs">
                      {cvDetail?.application?.evaluation?.locationMatchExplanation}
                    </p>
                  </li>
                </ul>

              </div>
              <div className="mb-3 text-sm">
                <p className="font-bold">
                  {t('bia.evaluate.strengths')}
                </p>
                <p>
                  {cvDetail?.application?.evaluation?.strengths}
                </p>
              </div>
              <div className="text-sm">
                <p className="font-bold">{t('bia.evaluate.weaknesses')}</p>
                <p>
                  {cvDetail?.application?.evaluation?.weaknesses}
                </p>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};
