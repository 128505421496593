import { useMemo, useState } from 'react';
import { Trans } from 'next-i18next';
import { JobMatch } from '@type/v1-api-types';
import { Tooltip } from '@components/common';
import { BullsEyeSolidIcon } from '@components/common/Icons';

// Interface
// ******************************************
interface JobMatchProps {
  jobMatches?: JobMatch[];
}

/**
 * @description Component that renders the first job match on a talent card.
 *
 * * **jobMatches** - The array of job matches. Only the first match is displayed.
 */
export const TalentCardJobMatch = (props: JobMatchProps) => {
  // Destructure props
  const { jobMatches } = props;

  // Tooltip state
  const [showTooltip, setShowTooltip] = useState(false);

  // If there are matches, get the first job match.
  const jobMatch = useMemo(
    () => {
      if (!jobMatches?.length) {
        return undefined;
      }

      return jobMatches.find((match) => match.score && match.jobTitle);
    },
    [jobMatches],
  );

  // Return null if no match found.
  if (!jobMatch) {
    return null;
  }

  // Render component
  return (
    <div className="mb-4 mx-4 rounded-full bg-secondary/10 px-4 py-2 flex items-center gap-x-2">
      <BullsEyeSolidIcon className="w-4 h-4 shrink-0 text-secondary" />
      <div className="flex-1 min-w-0">
        <Tooltip
          tooltipText={jobMatch.jobTitle as string}
          forceShowTooltip={showTooltip}
          forceHideTooltip={!showTooltip}
          position="top"
          colorScheme="black"
          displayStyle="block"
        >
          <span
            className="block text-xs text-ink/80 truncate relative z-[2] cursor-default"
            onMouseEnter={({ currentTarget }) => {
              /** Display a tooltip with the job title only when ellipsis is activated
               *  (job title is longer and does not fit on one line).
               */
              if (currentTarget.scrollWidth > currentTarget.clientWidth) {
                setShowTooltip(true);
              }
            }}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <Trans
              i18nKey="talent-card.v2.matching"
              values={{ score: jobMatch.score, jobTitle: jobMatch.jobTitle }}
            />
          </span>
        </Tooltip>
      </div>
    </div>
  );
};
