import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useTranslation } from 'next-i18next';
import { useAppSelector } from '@store/index';
import { getPaymentData } from '@store/reducers/employer/subscription/subscriptionSelector';
import { Modal } from '@components/common/Modal/Modal';
import { useHandleSubscriptionModalClose } from '@components/templates/employer/subscription/utils';
import { SubscriptionsHubModal } from '@components/templates/employer/subscription/SubscriptionsHubModal';


/**
 * @description Renders the subscriptions modal, with payment options, pricing and if
 * it's the case the 'not enough credits' and payment status possible steps.
 * The modal can be opened anywhere in the app using the **showUpgradeSubscriptionModal** function.
 */
export const SubscriptionsModal = NiceModal.create(() => {
  // Use a hook to manage the modal state
  const modal = useModal();

  // Close modal
  const closeModal = () => {
    void modal.hide();
  };

  // Translation
  const { t } = useTranslation('common');

  // Conditional size for modal
  const notSubscriptionSelectionStep = Boolean(useAppSelector(getPaymentData));

  // Cleanup Redux states and re-fetch after closing the modal depending on the flow step
  const { handleSubscriptionModalClose } = useHandleSubscriptionModalClose();

  // Render component
  // *****************************************
  return (
    <Modal
      isOpen={modal.visible}
      onClose={closeModal}
      afterLeave={handleSubscriptionModalClose}
      size={notSubscriptionSelectionStep ? 'lg' : 'xl'}
      header={t('subscription.limit.reached.title')}
      body={<SubscriptionsHubModal closeModal={closeModal} />}
    />
  );
});


/**
 * @description Opens the upgrade subscription modal anywhere in the app. Modal comes with subscriptions
 * selection, payment options, pricing and if it's the case the 'not enough credits' and payment
 * status possible steps.
 *
 * @example
 * // import
 * import { showSubscriptionsModal } from '@components/templates/employer/subscription/SubscriptionsModal';
 * // usage
 * <Button onClick={showSubscriptionsModal}>Show upgrade subscription modal</Button>
 */
export const showSubscriptionsModal = () => {
  void NiceModal.show(SubscriptionsModal);
};
