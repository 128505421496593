import { useTranslation } from 'next-i18next';
import {
  MapPinIcon, CircleStackIcon, BanknotesIcon,
} from '@heroicons/react/24/solid';

import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { CvDetailInterface, CvDetailPreferences } from '../interface/CvDetailInterface';

export const ProfilePreferences = ({ cvDetail }: CvDetailInterface) => {
  const { t } = useTranslation('common');

  // We need to order the preferences from cv detail by the current category, in order to show them
  const location = cvDetail?.preferences
      && cvDetail?.preferences.filter((item:CvDetailPreferences) => item.preferenceType.toString() === 'location');

  const timeAndPay = cvDetail?.preferences
      && cvDetail?.preferences.filter((item:CvDetailPreferences) => item.preferenceType.toString() === 'timeAndPay');


  return (
    <>
      { (location || timeAndPay || (cvDetail?.expectedSalary !== undefined && cvDetail?.expectedSalary !== 0))
      && (
      <section id="looking-for" className="py-7">
        <h6 className="mb-4 mr-2 border-b border-dotted pb-4 text-lg font-semibold">
          {t('cv.section.label.preferences.type')}
        </h6>

        <div className="mb-10">
          { location
          && (
          <>
            <div>
              <div className="mb-3 flex items-center text-sm font-bold text-ink">
                <MapPinIcon className="mr-3 size-5 text-ink" />
                {t('cv.section.label.preferences.location')}
              </div>
              { location.map((item:CvDetailPreferences) => (
                <div className="mb-3 flex items-start text-sm text-ink-medium" key={item.value}>
                  <div>
                    <CheckCircleIcon className="mr-3 inline-block size-5 text-ink-medium" />
                  </div>
                  {item.value}
                </div>
              ))}
            </div>
          </>
          ) }

          { (timeAndPay?.length !== 0)
              && (
                <div>
                  <div className="mb-3 flex items-center text-sm font-bold text-ink">
                    <CircleStackIcon className="mr-3 size-5 text-ink" />
                    {t('cv.section.label.preferences.time.pay')}
                  </div>
                  { timeAndPay !== undefined && timeAndPay.map((item:CvDetailPreferences) => (
                    <div className="mb-3 flex items-start text-sm text-ink-medium" key={item.value}>
                      <div>
                        <CheckCircleIcon className="mr-3 inline-block size-5 text-ink-medium" />
                      </div>
                      {item.value}
                    </div>
                  ))}
                </div>
              ) }

          { cvDetail?.expectedSalary
              && (
              <>
                <div>
                  <div className="mb-3 flex items-center text-sm font-bold text-ink">
                    <BanknotesIcon className="mr-3 size-5 text-ink" />
                    {t('cv.detail.expected.salary')}
                  </div>
                  <div className="mb-3 flex items-start text-sm text-ink-medium">
                    <div>
                      <CheckCircleIcon className="mr-3 inline-block size-5 text-ink-medium" />
                    </div>
                    {`${cvDetail?.expectedSalary} `}
                    {cvDetail?.salaryCurrency}
                  </div>
                </div>
              </>
              ) }
        </div>
      </section>
      )}
    </>
  );
};
