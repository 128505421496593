// Job List ***************************************************************************************
// pathname: /v1/jobs
// ************************************************************************************************
import {
  useSwrInfiniteApi,
  ApiJobListCards,
} from '@hooks/useSwrApi';
import { JOB_LIST_ITEMS_PER_PAGE } from 'src/constants';


// Interface
// ********************************************
interface JobListCardsInfiniteSwrProps {
  locale?: string;
  serverSideData?: ApiJobListCards;
  startIndex?: number;
  limit?: number;
  urlParams?: string;
}


/**
 * @description SWR: fetch job list cards with infinite pagination.
 *
 * * **locale**: string. What locale to use for the request.
 * * **serverSideData**: ApiJobListCards, optional. The data we got from the server side request (if we do it). Default undefined.
 * * **startIndex**: number, optional. The first page number to fetch (ie: fetch starting with page 5). Default 1.
 * * **limit**: number, optional. The number of job cards to get per call. Modifies the *limit* url parameter (&limit=24). Default 24.
 * * **restUrlParams**: '&sort=relevant', optional. Default empty string.
 */
export const useSwrJobListCardsInfinite = (props: JobListCardsInfiniteSwrProps) => {
  // Props destructuring
  // ********************************************
  const {
    locale,
    serverSideData,
    startIndex = 0,
    limit = JOB_LIST_ITEMS_PER_PAGE,
    urlParams = '',
  } = props;


  // Get data from API
  // ********************************************
  const {
    data,
    error,
    size,
    setSize,
    isValidating,
    isLoading,
    mutate,
    isEmpty,
    showLoadMore,
    sizeAsRef,
  } = useSwrInfiniteApi<ApiSchema<'JobResult'>>({
    url: '/v1/jobs',
    locale,
    urlParams,
    serverSideData,
    infiniteConfig: {
      paginationType: 'offset',
      limit,
      // the key where the array of items is located in the response
      responseArrayKey: 'items',
      startIndex,
    },
    apiOptions: {
      keepPreviousData: true,
      revalidateOnFocus: false,
      dedupingInterval: 1000 * 60, // 1 minute
    },
    toastsOptions: {
      showErrorToast: true,
    },
  });


  // Return data
  // ********************************************
  return {
    data,
    error,
    size,
    setSize,
    isValidating,
    isLoading,
    mutate,
    isEmpty,
    showLoadMore,
    sizeAsRef,
  };
};
