import { useTranslation } from 'next-i18next';
import React from 'react';
import { Button, ImageBj } from '@components/common';

import { useSwrResumeEvaluate } from '@hooks/useSwrApi/endpoints/resume-operations';
import { CvDetailObject } from '@components/templates/cv/interface/CvDetailInterface';
import hannaImage from '@images/agent/hanna.png';

interface ProfileEvaluationData {
  cvDetail?: CvDetailObject;
  jobSlug?: string;
  locale: string;
  evaluationGenerated?: () => void;
}

/**
 * @description Component to evaluate the resume with BIA and add a comment.
 */
export const ProfileCommentBiaAdd = (props: ProfileEvaluationData) => {
  const {
    cvDetail, jobSlug, locale, evaluationGenerated,
  } = props;

  const { t } = useTranslation('common');

  // Callback to execute on success evaluation
  const evaluationSuccess = () => {
    if (evaluationGenerated) {
      evaluationGenerated();
    }
  };

  // SWR: Evaluate resume
  const { trigger: evaluateResume, isMutating } = useSwrResumeEvaluate(
    locale,
    cvDetail?.id ?? 0,
    evaluationSuccess,
  );

  // Button to generate the evaluation
  return (
    <div className="border-y py-4">
      <div className="rounded border-2 border-secondary border-opacity-10">
        <div className="rounded border border-dashed border-secondary bg-surface p-4">
          <div className="mb-4 flex flex-row items-center">
            <div className="relative size-16 overflow-hidden rounded-full">
              <ImageBj priority src={hannaImage} alt="bia" />
            </div>

            <p className="ml-2 text-ink">{t('cv.comment.bia.add.title')}</p>
          </div>

          <Button
            styling="outline"
            color="primary-light"
            className="w-full"
            rounding="full"
            size="sm"
            isLoading={isMutating}
            onClick={() => {
              void evaluateResume({ jobSlug });
            }}
          >
            {t('cv.comment.bia.add.button')}
          </Button>
        </div>
      </div>
    </div>
  );
};
