import { useTranslation } from 'next-i18next';
import { bjToast, Button } from '@components/common';
import { ChangeEvent, useState } from 'react';
import { fetcher, isApiV1Errors } from '@utils/data-fetching';
import { useStaticToasts } from '@hooks/useStaticToasts';

export const HireSalaryModal = ({
  comingFrom,
  onSendSuccess,
  endpoint,
  applicantId,
  locale,
}: { comingFrom: string;
  endpoint: string;
  applicantId: number;
  onSendSuccess: () => void;
  locale: Locale;
}) => {
  const { t } = useTranslation('common');

  const [salaryInputValue, setSalaryInputValue] = useState<string>('');
  const { showStatusToast } = useStaticToasts();


  const sendHiredSalary = () => {
    if (comingFrom === 'confirm_salary_id') {
      fetcher(`/v1/hire/${applicantId}/add`, locale, {
        method: 'POST',
        payload: { salary: salaryInputValue },
        stringifyPayload: true,
      }).then((response) => {
        if (isApiV1Errors(response)) {
          const { code } = response.errors[0];
          showStatusToast(String(`${code}`));
        } else {
          onSendSuccess();
          bjToast.success(t('hired.applicant.approved'));
        }
      }).catch(() => {});
    }
    if (comingFrom === 'status_id') {
      fetcher(`${endpoint}`, locale, {
        method: 'POST',
        payload: { salary: salaryInputValue },
        stringifyPayload: true,
      }).then((response) => {
        if (isApiV1Errors(response)) {
          const { code } = response.errors[0];
          showStatusToast(String(`${code}`));
        } else {
          onSendSuccess();
          bjToast.success(t('hired.applicant.approved'));
        }
      }).catch(() => {});
    }
  };

  return (
    <>
      <div className="p-5 text-center">
        <span className="block text-center text-sm text-ink-medium mb-5">
          {comingFrom === 'confirm_salary_id' && t('confirm_hired.modal.desc')}
          {comingFrom === 'status_id' && t('confirm_hired_agent.modal.desc')}

        </span>
        <div className="relative flex items-center w-[115px] mx-auto mb-5">
          <input
            type="text"
            value={salaryInputValue}
            onChange={(e:ChangeEvent<HTMLInputElement>) => setSalaryInputValue(e.target.value)}
            className="h-[40px] w-[115px]"
            placeholder="ex: 450"
          />
          <div className="text-primary font-bold absolute right-[-5px] -translate-x-1/2">
            EUR
          </div>

        </div>
        <div className="text-center">
          <Button color="secondary" size="sm" onClick={sendHiredSalary} className="ml-1">{t('global.action.send')}</Button>
        </div>
      </div>
    </>
  );
};

