import { useRef, useContext, useEffect } from 'react';
import { TalentListsContext } from '@components/contexts/TalentListsContext';
import { useInfinitePrevNext } from '@hooks/infinite-helpers/useInfinitePrevNext';
import { Button } from '@components/common';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { ApiUserTalentItem, ApiTalentPageV3Ok } from '@type/v1-api-types';


// Interface
// **************************************
interface INavigationPropertiesInterface {
  slug: string,
  jobSlug?: string,
  isFromJobContext?: boolean,
  setLoadingCvStatusFromOperation: () => void;
}


/**
 * @description Navigation arrows for the CV detail page.
 * @param props
 */
export const ResumeArrowsNavigation = (props:INavigationPropertiesInterface) => {
  const {
    slug,
    jobSlug,
    isFromJobContext,
    setLoadingCvStatusFromOperation,
  } = props;


  // Get context data
  // **************************************
  const {
    swrTalentList,
    openTalentModal,
    showLoadMore,
    size,
    setSize,
  } = useContext(TalentListsContext) || {};

  // Get the previous and next CV slugs.
  // **************************************
  const {
    prevItem,
    nextItem,
    lastInList,
    nextItemPage,
    prevItemPage,
  } = useInfinitePrevNext<ApiUserTalentItem, ApiTalentPageV3Ok>({
    slug,
    data: swrTalentList,
    itemsFlatKey: 'items',
    withPageData: true,
  });

  // Refs
  // **************************************
  const nextRef = useRef<HTMLButtonElement>(null);
  const prevRef = useRef<HTMLButtonElement>(null);
  const listIsLoading = useRef(false);


  // Helper functions
  // **************************************
  const handleArrowClick = (slugDirection?: string, pageReqId?: string) => {
    setLoadingCvStatusFromOperation();
    // Build the rid analytics tracking
    const ridTracking = pageReqId ? `?rid=${pageReqId}` : '';
    if (slugDirection && openTalentModal) openTalentModal(slugDirection, isFromJobContext ? `/job/${jobSlug}/cv/${slugDirection}${ridTracking}` : `/cv/${slugDirection}${ridTracking}`);
  };


  // Load more talents if we are at the end of the list but there are more pages to load.
  // **************************************
  useEffect(() => {
    if (lastInList && listIsLoading?.current === false && showLoadMore && size && setSize) {
      listIsLoading.current = true;
      void setSize(size + 1);
    }

    const timer = setTimeout(() => {
      listIsLoading.current = false;
    }, 1000);

    return () => clearTimeout(timer);
  }, [lastInList, showLoadMore, size, setSize]);

  // Keyboard navigation. Check if the event is from a textarea and return if it is, maybe the user is navigating
  // through the textarea.
  // **************************************
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.target instanceof HTMLTextAreaElement) return;

      if (event.key === 'ArrowRight') {
        event.preventDefault();
        nextRef?.current?.click();
      }

      if (event.key === 'ArrowLeft') {
        event.preventDefault();
        prevRef?.current?.click();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  // Don't render if there are no previous or next items or slug.
  // **************************************
  if (!slug || (!prevItem && !nextItem)) return null;

  // Render component
  // **************************************
  return (
    <div className="flex items-center justify-end text-right">

      { /* Previous CV button */
        prevItem?.slug && (
          <Button styling="none" ref={prevRef} onClick={() => handleArrowClick(prevItem?.slug, prevItemPage?.apiRequestId)}>
            <span className="relative mr-2 flex size-7 cursor-pointer items-center justify-center rounded-full bg-surface md:mr-4 md:size-8">
              <ChevronLeftIcon className="size-5 text-ink" />
            </span>
          </Button>
        )
      }

      { /* Next CV button */
        nextItem?.slug && (
          <Button styling="none" ref={nextRef} onClick={() => handleArrowClick(nextItem?.slug, nextItemPage?.apiRequestId)}>
            <span className="relative mr-2 flex size-7 cursor-pointer items-center justify-center rounded-full bg-surface md:mr-0 md:size-8">
              <ChevronRightIcon className="size-5 text-ink" />
            </span>
          </Button>
        )
      }

    </div>
  );
};

