import { Anchor } from '@components/common';
import { useTranslation } from 'next-i18next';
import { CvDetailObject } from '../interface/CvDetailInterface';

// This Component return the last applications of the user, it is displayed in the right side
// of cv detail
export const ProfileApplications = ({ cvDetail, jobFolderHighlight }: { cvDetail:CvDetailObject; jobFolderHighlight:boolean }) => {
  const { t } = useTranslation('common');

  function cvStatusColor(statusId: number): string {
    let cvTextColor;

    switch (statusId) {
      case 3:
        cvTextColor = ' text-pink';
        break;
      case 21:
        cvTextColor = ' text-primary';
        break;
      case 4:
        cvTextColor = ' text-error';
        break;
      case 2:
        cvTextColor = ' text-secondary';
        break;
      case 22:
        cvTextColor = ' text-secondary';
        break;
      default:
        cvTextColor = ' text-primary';
    }

    return cvTextColor;
  }

  return (
    <>
      {cvDetail?.applications
            && (
            <section className="py-4">
              {cvDetail.applications.length > 0
                  && (
                  <h3 className="text-text-lg relative flex items-center font-bold">
                    {t('cv.detail.application_history.title')}
                    {jobFolderHighlight && (
                    <>
                      <span className="relative ml-2 flex size-3">
                        <span className="absolute inline-flex size-full animate-ping rounded-full bg-primary opacity-75" />
                        <span className="relative inline-flex size-3 rounded-full bg-primary" />
                      </span>
                    </>
                    )}
                  </h3>
                  )}

                {cvDetail.applications.map((item: { jobTitle:string;createdAt:string;jobUrl:string;status:string;statusId:string }) => (
                  <div
                    key={item.jobTitle + item.createdAt + item.status}
                    className="border-b py-2"
                  >

                    <Anchor
                      href={item.jobUrl}
                      styling="text"
                      className="my-1 block text-sm font-bold text-primary"
                    >
                      {item.jobTitle}
                    </Anchor>
                    {' '}
                    <span className="ml-1"> - </span>
                    <span className={`ml-1 text-sm${cvStatusColor(parseInt(item.statusId, 10))}`}>{item.status}</span>
                  </div>
                ))}
            </section>
            )}
    </>
  );
};
