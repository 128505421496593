// Company Landing ********************************************************************************
// pathname: /v1/order/update-cart/cartId
// ************************************************************************************************
import {
  useSwrMutationApi,
} from 'src/hooks/useSwrApi';

/**
 * @description Hook interface
 */
interface UseSwrOrderUpdateCart {
  locale: string,
  orderId?: string | number,
  successCallback?: () => void,
}

/**
 * @description SWR: fetch company landing page data.
 * - `locale` string - the locale to use for the request.
 * - `orderId` string - the order id.
 * - `successCallback` function - the callback to call on success.
 */
export const useSwrMutationOrderUpdateCart = (props: UseSwrOrderUpdateCart) => {
  // Props destructuring
  const {
    locale,
    orderId,
    successCallback,
  } = props;

  // SWR wrapper
  const {
    data, error, trigger, isMutating, reset,
  } = useSwrMutationApi<ApiSchema<'OrderPayment'>, object >({
    url: `/v1/order/update-cart/${orderId}`,
    locale,
    fetcherConfig: {
      method: 'POST',
      stringifyPayload: true,
    },
    apiOptions: {
      onSuccess: () => {
        if (successCallback) successCallback();
      },
    },
  });


  // Return data
  // ********************************************
  return {
    data,
    error,
    trigger,
    isMutating,
    reset,
  };
};
