import { InterfaceJobFolderOptions, ProfileHeading } from '@components/templates/cv/sections/ProfileHeading';
import {
  CvDetailAvailableOperationsCurrentStatus,
  CvDetailObject,
} from '@components/templates/cv/interface/CvDetailInterface';
import { ProfileSkills } from '@components/templates/cv/sections/ProfileSkills';
import { ProfileExperience } from '@components/templates/cv/sections/ProfileExperience';
import { ProfileAbout } from '@components/templates/cv/sections/ProfileAbout';
import { ProfileStudies } from '@components/templates/cv/sections/ProfileStudies';
import { ProfilePreferences } from '@components/templates/cv/sections/ProfilePreferences';
import { TagCloud } from '@components/templates/cv/sections/TagCloud';
import { ProfileTalentRating } from '@components/templates/cv/sections/ProfileTalentRating';
import { ProfileAgentData } from '@components/templates/cv/sections/ProfileAgentData';
import { EvaluateBia } from '@components/templates/cv/sections/EvaluateBia';
import { ProfileComments } from '@components/templates/cv/sections/ProfileComments';
import { ProfileInterview } from '@components/templates/cv/sections/ProfileInterview';
import { ProfileApplications } from '@components/templates/cv/sections/ProfileApplications';
import { useState } from 'react';
import { useAuthClient } from '@hooks/common/useAuthClient/useAuthClient';

interface IProps {
  cvDetail: CvDetailObject;
  applicantSeenContactStatus: boolean;
  cvCurrentJobUnlockFolder: InterfaceJobFolderOptions;
  setJobFolderInfo: (jobFolderInfo: InterfaceJobFolderOptions) => void;
  phoneHighlight: boolean;
  currentCVStatus: CvDetailAvailableOperationsCurrentStatus | undefined;
  jobFolderSlug?: string;
  jobFolderContext?: boolean;
  jobSlug?: string,
  openLinksInNewTab?: boolean;
  queryParams?: string;
  isCompany?: boolean;
  locale: Locale;
  jobFolderHighlight: boolean
}

export const ProfileResumeSections = ({
  setJobFolderInfo, cvDetail, jobFolderSlug, currentCVStatus, applicantSeenContactStatus, cvCurrentJobUnlockFolder,
  phoneHighlight, jobSlug, jobFolderHighlight, jobFolderContext, openLinksInNewTab = false, queryParams, isCompany, locale,
}: IProps) => {
  const resumeSearchObject = Object.fromEntries(new URLSearchParams(queryParams));

  const [talentRating, setTalentRating] = useState<number>(cvDetail?.talentRatingData?.ratingGiven ?? 0);
  const canGiveRating = !!(!cvDetail?.isOwnCV && cvDetail?.talentRatingData?.canRate);

  const DEFAULT_OWN_CV = cvDetail?.isOwnCV;
  const { isAuth } = useAuthClient();

  return (
    <section>
      <div className="grid-cols-10 gap-5 rounded-md bg-surface py-5 lg:grid ">
        <div className="col-span-3 pr-0 lg:border-r lg:border-dotted lg:pr-4">
          <ProfileHeading
            applicantSeenContactStatus={applicantSeenContactStatus}
            jobFolderSlug={jobFolderSlug ?? ''}
            jobFolderContext={!!jobFolderContext}
            currentJobFolderItem={!jobFolderSlug ? cvCurrentJobUnlockFolder : {
              jobId: 0,
              title: '',
              jobSlug: jobFolderSlug ?? '',
            } as InterfaceJobFolderOptions}
            sendJobFolderInfoToParent={!jobFolderSlug ? setJobFolderInfo : () => undefined}
            phoneHighlight={phoneHighlight}
            cvDetail={cvDetail}
            currentCVStatus={currentCVStatus}
            openLinksInNewTab={openLinksInNewTab}
            isCompany={isCompany}
            locale={locale}
          />

          <ProfileAbout
            cvDetail={cvDetail}
            keyword={resumeSearchObject?.keyword}
          />

          <TagCloud
            cvDetail={cvDetail}
          />

          <ProfilePreferences
            cvDetail={cvDetail}
          />

        </div>
        <div className={`${(DEFAULT_OWN_CV || !isAuth) ? 'col-span-7' : 'col-span-4'}`}>
          <ProfileExperience
            cvDetail={cvDetail}
            keyword={resumeSearchObject?.keyword}
          />

          <ProfileStudies
            cvDetail={cvDetail}
            keyword={resumeSearchObject?.keyword}
          />

          <ProfileSkills
            cvDetail={cvDetail}
            keyword={resumeSearchObject?.keyword}
          />
        </div>

        {!DEFAULT_OWN_CV && isAuth && (
          <div className="col-span-3 lg:border-l lg:border-dotted">
            <div className="bg-surface pl-0 lg:pl-4">
              <ProfileTalentRating
                onSaveRating={(value) => setTalentRating(value)}
                talentRating={talentRating}
                cvDetail={cvDetail}
                locale={locale}
                canGiveRating={canGiveRating}
              />
              {cvDetail?.agent !== undefined && cvDetail?.application?.isRecommended && (
                <ProfileAgentData
                  cvDetail={cvDetail}
                />
              )}
              <EvaluateBia cvDetail={cvDetail} />
              <ProfileComments
                cvDetail={cvDetail}
                locale={locale}
                jobSlug={jobSlug}
                currentCVStatus={currentCVStatus}
                isCompany={isCompany}
              />
              <ProfileInterview cvDetail={cvDetail} />
              <ProfileApplications
                jobFolderHighlight={jobFolderHighlight}
                cvDetail={cvDetail}
              />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};
