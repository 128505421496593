// RESUME DELETE COMMENT ******************************************************************************
// pathname: /v1/resume-operation/${commentId}/comment
// ****************************************************************************************************
import { useSwrMutationApi } from 'src/hooks/useSwrApi';

/**
 * @description Hook interface
 */
interface UseSwrOrderDeleteCart {
  locale: string,
  shoppingCartId?: string | number,
  successCallback?: () => void,
}

/**
 * @description SWR mutation: DELETE cart
 * - `locale` - the locale.
 * - `shoppingCartId` - the shopping cart id.
 * - `successCallback` - function to call on success.
 */
export const useSwrOrderDeleteCart = (props: UseSwrOrderDeleteCart) => {
  // Destructure props
  const {
    locale,
    shoppingCartId,
    successCallback,
  } = props;

  // SWR mutation
  const {
    data, error, isMutating, reset, trigger,
  } = useSwrMutationApi<object, object>({
    url: `/v1/order/delete-cart/${shoppingCartId}`,
    locale,
    fetcherConfig: {
      method: 'DELETE',
      stringifyPayload: true,
    },
    apiOptions: {
      onSuccess: () => {
        if (successCallback) successCallback();
      },
    },
    toastsOptions: {
      showErrorToast: true,
    },
  });


  // Return data
  // ********************************************
  return {
    data,
    error,
    isMutating,
    reset,
    trigger,
  };
};
