import { ArrowDownTrayIcon, ShareIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { ChatBubbleLeftEllipsisIcon, PhoneIcon } from '@heroicons/react/20/solid';

import { TailwindSpinner } from '@components/common/Elements/Spinner';
import {
  MouseEvent, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  CvDetailAvailableOperations,
  CvDetailAvailableOperationsCurrentStatus,
  CvDetailObject,
} from '@components/templates/cv/interface/CvDetailInterface';
import {
  Button, Dropdown, Modal, Tooltip,
} from '@components/common';
import { useBjModal } from '@hooks/common/useBjModal';
import { ShareModal } from '@components/features/modals/share-modal/ShareModal';
import { CustomConfirmModalContent } from '@components/templates/cv/partials/modals/CustomConfirmModalContent';
import { useTranslation } from 'next-i18next';
import { useStaticToasts } from '@hooks/useStaticToasts/useStaticToasts';
import { SendMessageModalContent } from '@components/templates/cv/partials/modals/SendMessageModalContent';
import { ResumeArrowsNavigation } from '@components/templates/cv/partials/navigation/ResumeArrowsNavigation';
import sendGoogleTagEvent from '@hooks/useSendGoogleTagEvent';
import {
  ApiCheckUnlock,
  ApiCheckUnlockPay,
  fetcher,
  isApiV1Errors,
  isCheckUnlockStatusOk,
  isCheckUnlockStatusPayOk,
  ResponseWithHeaders,
} from '@utils/data-fetching';
import useWindowDimensions from '@hooks/useMediaQuery';
import { useAppDispatch, useAppSelector } from 'src/app';
import { showUpgradeSubscriptionModal } from '@components/features/modals/employer/subscription/UpgradeSubscriptionModal';
import { AddressBookSolidIcon } from '@components/common/Icons';
import { useApplicantListsRedux } from '@components/templates/employer/cards-lists/hooks/useApplicantListsRedux';
import { useOpenTokCallStart } from '@components/features/openTokCall';
import { showCvContactModal } from '@components/features/modals/employer/CvContactModal';
import { ProfileOperationItemButton } from '@components/templates/cv/sections/ProfileOperationItemButton';
import { useSwrAvailableResumeOperations } from '@hooks/useSwrApi/endpoints/user';
import { filterAndOrderAvailableOperations } from '@components/templates/cv/utils';
import { getCommentUnlockResponse } from '@store/reducers/employer/resume/resumeSelector';
import { clearCommentUnlockResponse } from '@store/reducers/employer/resume/resumeReducer';
import { EmployerResumeUnlockResponse } from '@type/redux';
import { getHoverClassesByOperation } from '@components/templates/cv/utils/operations';
import { useSwrResumeCheckUnlockPrice } from '@hooks/useSwrApi/endpoints/resume-operations/useSwrResumeCheckUnlockPrice';
import { showAddCompanyInfoModal } from '@components/features/modals/employer/AddCompanyInfoModal';

export const ProfileOperations = ({
  cvDetail,
  reFetchData,
  jobFolderId,
  isSinglePage,
  jobTitle,
  jobSlug,
  sendCurrentCvStatus,
  slug,
  setLoadingCvStatus,
  setTelephoneHighlight,
  setCurrentJobFolderHighlight,
  isFromJobContext,
  applicantSeenContact,
  applicantSeenContactStatus,
  closeCvModal,
  isCompany,
  rid,
  withNavbarInModal,
  locale,
  statusChangeFromModal,
}: { cvDetail:CvDetailObject;
  setLoadingCvStatus:()=>void;
  statusChangeFromModal?:(statusId: string, slug:string)=>void;
  setTelephoneHighlight:()=>void;
  setCurrentJobFolderHighlight:()=>void;
  jobSlug:string;
  jobTitle:string;
  isSinglePage?:boolean;
  slug:string;
  jobFolderId:number;
  isFromJobContext:boolean;
  applicantSeenContactStatus:boolean;
  reFetchData:(onSuccessCallback?: (cv: CvDetailObject) => void) => void;
  applicantSeenContact:() => void;
  sendCurrentCvStatus:(item: CvDetailAvailableOperationsCurrentStatus) => void;
  closeCvModal?: () => void;
  isCompany: boolean;
  rid?: string;
  withNavbarInModal?: boolean;
  locale: Locale;
}) => {
  const { t } = useTranslation('common');
  const [isLoadingDownload, setLoadingDownload] = useState<boolean>(false);
  const userSlug = cvDetail.slug;
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const userIdToUnlock = cvDetail?.id as number;
  const [sendMessageModal, setSendMessageModal] = useState<boolean>(false);
  const [canSendMessage, setCanSendMessage] = useState<boolean>(false);
  const [unlockedByCall, setUnlockedByCall] = useState<boolean>(false);
  const [currentCVStatus, setCurrentCVStatus] = useState<CvDetailAvailableOperationsCurrentStatus>();
  const [currentJobSlug, setCurrentJobSlug] = useState<string>('');
  const telRef = useRef<HTMLAnchorElement>(null);
  const { status200Toast, showStatusToast } = useStaticToasts();
  const { isMobile } = useWindowDimensions();
  const headerInfo = useAppSelector((state) => state.user.headerInfo);
  const userId = headerInfo?.userId;

  const [isLoadingSendMessage, setIsLoadingSendMessage] = useState<boolean>(false);
  const [isLoadingCallNow, setIsLoadingCallNow] = useState<boolean>(false);
  const [isLoadingStatusChange, setIsLoadingStatusChange] = useState<boolean>(false);

  // Redux states for checking external operations response
  const commentUnlockResponse = useAppSelector(getCommentUnlockResponse);
  const dispatch = useAppDispatch();

  // Redux states for infinite lists. When in modal, with this we watch if:
  // - The applicant/talent status has changed (interview, shortlist, etc.).
  // - The CV was previously locked but now has been unlocked via message, show contact details, download, etc.
  // We will mutate the infinite list according to these states.
  // *************************************
  const [statusChanged, setStatusChanged] = useState<boolean>(false);
  const [lockedChanged, setLockedChanged] = useState<boolean>(false);
  const [isFreeCvInfoUnblock, setIsFreeCvInfoUnblock] = useState<boolean>(false);
  const [newStatusId, setNewStatusId] = useState<number | undefined>();
  useApplicantListsRedux({
    statusChanged, lockedChanged, slug, statusId: newStatusId,
  });


  // SWR mutation: Unlock for Message
  // ******************************************************
  const {
    trigger: triggerCheckUnlockPrice,
  } = useSwrResumeCheckUnlockPrice({
    userSlug,
    locale,
    successCallback: (response) => {
      // If the response is false, the contact cost is 10 neuro
      setIsFreeCvInfoUnblock(response.is_unlock);
    },
  });


  // Handle share modal
  // *************************************
  const { isOpen: isShareModalOpen, toggleModal: toggleShareModal } = useBjModal();


  // start video call options
  const { onShowVideoOptions } = useOpenTokCallStart();

  // SWR: Get available operations
  const { data: availableResumeOperations, mutate: refetchResumeOperations } = useSwrAvailableResumeOperations({
    locale,
    slug,
    shouldFetch: isCompany,
    queryParams: {
      jobSlug: jobSlug?.length ? jobSlug : currentJobSlug,
      rid,
    },
    onSuccess: (response) => {
      setCurrentCVStatus(response?.currentStatus as CvDetailAvailableOperationsCurrentStatus);
      sendCurrentCvStatus(response?.currentStatus as CvDetailAvailableOperationsCurrentStatus);
    },
  });

  // List of available operations filtered and ordered
  const availableOperations = useMemo(() => filterAndOrderAvailableOperations(
    availableResumeOperations?.availableOperations ?? [],
  ), [availableResumeOperations?.availableOperations]);

  const handleSuccessStatusChanged = useCallback((response: EmployerResumeUnlockResponse) => {
    setCurrentJobSlug(response.response.jobSlug);
    // If the response status is success we need to re-fetch the available operations
    void refetchResumeOperations();
    sendGoogleTagEvent(`employer_cv_set_${response.operationName?.toLowerCase()}`, 'Custom');
    setIsLoadingStatusChange(false);
    setStatusChanged(true);
    setNewStatusId(response.statusId);
  }, [refetchResumeOperations]);

  useEffect(() => {
    if (commentUnlockResponse) {
      dispatch(clearCommentUnlockResponse());
      handleSuccessStatusChanged(commentUnlockResponse);
    }
  }, [commentUnlockResponse, dispatch, handleSuccessStatusChanged]);


  useEffect(() => {
    void triggerCheckUnlockPrice({ jobId: jobFolderId, title: jobTitle, jobSlug });
  }, [triggerCheckUnlockPrice, jobFolderId, jobTitle, jobSlug]);


  const sendContactedData = () => {
    setIsLoadingCallNow(true);

    fetcher(`/v1/resume-operation/${userIdToUnlock}/contact${rid ? `?rid=${rid}` : ''}`, locale, {
      method: 'POST',
      payload: {
        type: 'contact',
        jobSlug,
      },
      stringifyPayload: true,
    }).then((response) => {
      if (isApiV1Errors(response)) {
        const { code } = response.errors[0];
        showStatusToast(String(`${code}`));
      } else if (!applicantSeenContactStatus) {
        applicantSeenContact();
        setIsLoadingCallNow(false);
        setTelephoneHighlight();
      }
    }).catch(() => {
    });
  };


  // we use the fetcher version with headers, because we need to get the filename from the response headers
  const getDownloadedPDF = () => {
    const apiURL = !isFromJobContext ? `/v1/resume-operation/${userIdToUnlock}/download-pdf${rid ? `?rid=${rid}` : ''}` : `/v1/resume-operation/${userIdToUnlock}/job/${jobSlug}/download-pdf${rid ? `?rid=${rid}` : ''}`;
    fetcher<unknown>(apiURL, locale, {
      method: 'GET',
      responseType: 'blob',
      withResponseHeaders: true,
    }).then((res) => {
      // this is how you make the response headers available and let TS know about it (assert res as ResponseWithHeaders<T>)
      const { response, responseHeaders }: { response: BlobPart, responseHeaders: Headers } = res as ResponseWithHeaders<BlobPart>;
      const filename = responseHeaders.get('content-disposition')?.split('filename=')[1];
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename || `${userSlug}.pdf`);
      document.body.appendChild(link);
      link.click();
      setLoadingDownload(false);
      reFetchData();
    }).catch(() => {});
  };

  // Unlock with PDF if the confirmation modal is true
  const getConfirmModalValue = (isApproved?:boolean) => {
    if (isApproved !== false) {
      fetcher<ApiCheckUnlock>(`/v1/resume-operation/${userIdToUnlock}/check-unlock/download${rid ? `?rid=${rid}` : ''}`, locale, {
        method: 'POST',
        payload: { jobId: jobFolderId, title: jobTitle, jobSlug },
        stringifyPayload: true,
      }).then((response) => {
        if (isCheckUnlockStatusOk(response)) {
          sendGoogleTagEvent('premium_contact_download', 'Custom');
          getDownloadedPDF();
          reFetchData();
          void refetchResumeOperations();
          setConfirmModal(false);
          setLockedChanged(true);
          if (response.meta?.consumptionInfoSummary) {
            status200Toast(response.meta.consumptionInfoSummary);
          }
        } else if (isApiV1Errors(response)) {
          const { code } = response.errors[0];

          if (code === 701) {
            showAddCompanyInfoModal(locale, getConfirmModalValue);
            return;
          }
          // If the error status code is 601 (not enough credits) we show the upgrade subscription modal.
          // Otherwise, we show a toast with the error message.
          if (code === 601) {
            showUpgradeSubscriptionModal();
          } else {
            showStatusToast(String(`${code}`));
          }
        }
      }).catch(() => {});
    } else {
      setConfirmModal(false);
      setLoadingDownload(false);
    }
  };
  // If the CV in unlocked than we can download the PDF else we are going in the process
  const checkUnlockPdf = () => {
    setLoadingDownload(true);
    if (cvDetail.locked === false) {
      getDownloadedPDF();
    } else {
      setConfirmModal(true);
    }
  };
  const unlockWithMessage = () => {
    setIsLoadingSendMessage(true);
    if (canSendMessage && cvDetail.locked === false) {
      setSendMessageModal(true);
      setIsLoadingSendMessage(false);
    } else {
      fetcher(`/v1/resume-operation/${userIdToUnlock}/check-unlock/message${rid ? `?rid=${rid}` : ''}`, locale, {
        method: 'POST',
        payload: { jobId: jobFolderId, title: jobTitle, jobSlug },
        stringifyPayload: true,
        withResponseHeaders: true,
      }).then((res) => {
        const { status }: { status: number } = res as ResponseWithHeaders<ApiCheckUnlock>;

        if (status === 402) {
          const { response }: { response: ApiCheckUnlockPay } = res as ResponseWithHeaders<ApiCheckUnlockPay>;
          if (isCheckUnlockStatusPayOk(response)) {
            showCvContactModal(response?.id, locale);
          }
          return;
        }
        const { response }: { response: ApiCheckUnlock } = res as ResponseWithHeaders<ApiCheckUnlock>;
        if (isCheckUnlockStatusOk(response)) {
          setCurrentJobFolderHighlight();
          setSendMessageModal(true);
          reFetchData();
          void refetchResumeOperations();
          setCanSendMessage(true);
          sendGoogleTagEvent('premium_contact_message', 'Custom');
          setIsLoadingSendMessage(false);
          setLockedChanged(true);
          if (response.meta?.consumptionInfoSummary) {
            status200Toast(response.meta.consumptionInfoSummary);
          }
        } else if (isApiV1Errors(response)) {
          const { code } = response.errors[0];

          if (code === 403) {
            showAddCompanyInfoModal(locale, unlockWithMessage);
            return;
          }
          // If the error status code is 601 (not enough credits) we show the upgrade subscription modal.
          // Otherwise, we show a toast with the error message.
          if (code === 601) {
            showUpgradeSubscriptionModal();
          } else {
            showStatusToast(String(`${code}`));
          }
          setIsLoadingStatusChange(false);
          setIsLoadingSendMessage(false);
        }
      }).catch(() => {});
    }
  };
  const unlockContactData = (onSuccessCallback?: (cv: CvDetailObject) => void) => {
    setIsLoadingCallNow(true);
    if (cvDetail?.privateInformation?.phone !== undefined && unlockedByCall) {
      setUnlockedByCall(false);
      setIsLoadingCallNow(false);
      if (onSuccessCallback) onSuccessCallback(cvDetail);
    } else {
      fetcher(`/v1/resume-operation/${userIdToUnlock}/check-unlock/contact${rid ? `?rid=${rid}` : ''}`, locale, {
        method: 'POST',
        payload: { jobId: jobFolderId, title: jobTitle, jobSlug },
        stringifyPayload: true,
        withResponseHeaders: true,
      }).then((res) => {
        const { status }: { status: number } = res as ResponseWithHeaders<ApiCheckUnlock>;

        if (status === 402) {
          const { response }: { response: ApiCheckUnlockPay } = res as ResponseWithHeaders<ApiCheckUnlockPay>;
          if (isCheckUnlockStatusPayOk(response)) {
            showCvContactModal(response?.id, locale);
          }
          return;
        }
        const { response }: { response: ApiCheckUnlock } = res as ResponseWithHeaders<ApiCheckUnlock>;
        if (isCheckUnlockStatusOk(response)) {
          sendContactedData();
          setCurrentJobFolderHighlight();
          setUnlockedByCall(true);
          reFetchData(onSuccessCallback);
          void refetchResumeOperations();
          sendGoogleTagEvent('premium_contact_call', 'Custom');
          setTimeout(() => {
            telRef?.current?.click();
          }, 750);
          setIsLoadingCallNow(false);
          setLockedChanged(true);
          if (response.meta?.consumptionInfoSummary) {
            status200Toast(response.meta?.consumptionInfoSummary);
          }
        } else if (isApiV1Errors(response)) {
          const { code } = response.errors[0];
          setIsLoadingCallNow(false);

          if (code === 701 || code === 403) {
            showAddCompanyInfoModal(locale, unlockContactData);
            return;
          }

          // If the error status code is 601 (not enough credits) we show the upgrade subscription modal.
          // Otherwise, we show a toast with the error message.
          if (code === 601) {
            showUpgradeSubscriptionModal();
          } else {
            showStatusToast(String(`${code}`));
          }
        }
      }).catch(() => {});
    }
  };

  // Change status to specific operation, each item has the parameters in the available
  // action we need to get them out
  const applyStatusChange = useCallback((params:CvDetailAvailableOperations) => {
    setIsLoadingStatusChange(true);
    const eventName = (params?.name ?? '').toLowerCase();
    const uId = params.operationParams.filter((x:{ id:string }) => x.id === 'userId').map((item : { value:number }) => item.value)[0];
    const jobId = params.operationParams.filter((x:{ id:string }) => x.id === 'jobId').map((item : { value:number }) => item.value)[0];
    const statusId = params.operationParams.filter((x:{ id:string }) => x.id === 'status').map((item : { value:number }) => item.value)[0];

    // In case of CV view, if we have dropdown for job select we need to overwrite the job context,
    // so that the operation will be applied to job context
    const jobFinalId = jobId === undefined ? jobFolderId : jobId;

    fetcher<ApiCheckUnlock>(`${params.operationEndpoint}${rid ? `?rid=${rid}` : ''}`, locale, {
      method: 'POST',
      payload: {
        userId: uId,
        status: statusId,
        jobId: jobFinalId,
        title: jobTitle,
      },
      stringifyPayload: true,
    }).then((response) => {
      if (isCheckUnlockStatusOk(response)) {
        handleSuccessStatusChanged({
          response,
          operationName: eventName,
          statusId,
        });
        statusChangeFromModal?.(statusId.toString(), slug);
      } else if (isApiV1Errors(response)) {
        const { code } = response.errors[0];
        if (code === 403) {
          const successCallback = () => applyStatusChange(params);
          showAddCompanyInfoModal(locale, successCallback);
          setIsLoadingStatusChange(false);
          return;
        }
        showStatusToast(String(`${code}`));
        setIsLoadingStatusChange(false);
      }
    }).catch(() => {});
  }, [jobFolderId, rid, locale, jobTitle, showStatusToast, handleSuccessStatusChanged, statusChangeFromModal, slug]);


  // We need to check if the phone was unlocked by request, or it can make already call without
  // checking unlock
  useEffect(() => {
    if (cvDetail?.privateInformation?.phone !== undefined && unlockedByCall) {
      setUnlockedByCall(false);
    }
  }, [unlockedByCall, cvDetail]);

  const setLoadingCvStatusFromOperation = () => {
    setLoadingCvStatus();
  };

  const triggerPhoneLoad = (event: MouseEvent) => {
    if (!isMobile) {
      event.preventDefault();
    }

    setIsLoadingCallNow(true);
    setTimeout(() => {
      setIsLoadingCallNow(false);
    }, 1500);
    setTelephoneHighlight();
  };


  // Helper component
  // *****************************************************************************************************
  // Return the user status
  // *****************************************************************************************************
  const returnUserStatus = useCallback(() => {
    const defaultClasses = 'text-pico lg:text-tiny uppercase text-ink flex items-center absolute shadow-xl bg-surface rounded-xl px-2 bottom-0 mt-2 text-nowrap';

    if (cvDetail?.online) {
      return (
        <span className={defaultClasses}>
          <span className="mr-2 block size-2.5 rounded-full bg-secondary" />
          {t('cv.detail.action.online.label')}
        </span>
      );
    }
    return null;
  }, [t, cvDetail?.online]);


  // Helper component
  // *****************************************************************************************************
  // Available operations buttons based on the current status and showing position (left right)
  // Left buttons are for the rejected status, right buttons are for the other statuses
  // *****************************************************************************************************
  const availableOperationsButtons = useCallback((isLeftButton: boolean) => {
    if (isLoadingStatusChange || !availableOperations?.length) {
      return null;
    }

    // Filter the operations based on the current status
    const filteredOperations = availableOperations.filter((item) => {
      const leftCondition = (item.operationId === 'status_2' && currentCVStatus?.id === '4') || item.operationId === 'status_4';
      if (isLeftButton) {
        return leftCondition;
      }
      return !leftCondition;
    });


    return (
      <>
        { filteredOperations.map((item) => {
          const hoverClasses = getHoverClassesByOperation(item);

          return (
            <div key={item.name}>
              <div>
                <div
                  role="presentation"
                  onClick={() => applyStatusChange(item)}
                  className={`mx-1 inline-flex
                            cursor-pointer items-center justify-center
                            rounded-full bg-surface px-4.5
                            py-3.5 text-md font-medium transition-all duration-200
                            hover:shadow-md disabled:opacity-60  ${hoverClasses}
                  ${item.operationId === 'status_21' ? ' bg-primary/10 text-primary' : ''}
                  ${item.operationId === 'status_22' ? ' bg-secondary/10 text-secondary' : ''}
                  ${item.operationId === 'status_3' ? ' bg-pink/10 text-pink' : ''}
                  ${item.operationId === 'status_4' ? ' bg-primary/10 text-primary' : ''}
                  ${(item.operationId === 'status_2' && currentCVStatus?.id === '22') ? ' !bg-secondary/10 text-secondary' : ''}
                  ${(item.operationId === 'status_2' && currentCVStatus?.id === '4') ? ' !bg-error/10 text-error' : ''}
              `}
                >
                  <ProfileOperationItemButton operationItem={item} currentCVStatus={currentCVStatus} />
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  }, [isLoadingStatusChange, availableOperations, currentCVStatus, applyStatusChange]);


  // success data unlocked - show the video call options
  const onSuccessContactDataUnlocked = (cv: CvDetailObject, withCall = false) => {
    if (withCall) {
      onShowVideoOptions({
        userId: cv.id as number,
        userName: cv.privateInformation?.name ?? '',
        slug: cv.slug,
        imgUrl: cv.imgUrl,
        email: cv.privateInformation?.email,
        phone: cv.privateInformation?.phone,
      });
    }
  };

  // Unlock contact data and show the phone number.
  // Show call options based on the `withCall` parameter
  const onViewContactDataPressed = (event: MouseEvent, withCall = false) => {
    // Check if the user has a phone number, if not we need to unlock it
    if (cvDetail?.privateInformation?.phone === undefined && !unlockedByCall) {
      unlockContactData((cv) => onSuccessContactDataUnlocked(cv, withCall));
      return;
    }
    if (!applicantSeenContactStatus) {
      sendContactedData();
      return;
    }
    triggerPhoneLoad(event);
    onSuccessContactDataUnlocked(cvDetail, withCall);
  };

  // Render main component
  // *****************************************************************************************************
  return (
    <>

      {/* Share modal */}
      <ShareModal
        isOpen={isShareModalOpen}
        onClose={toggleShareModal}
        pathnameShareUrl={locale === 'ro' ? `/cv/${userSlug}` : `/${locale}/cv/${userSlug}`}
        ogTitle={t('cv.detail.meta.title')}
        ogDescription={`Bestjobs - ${t('cv.detail.meta.title')} - ${userSlug}`}
      />

      <Modal
        size="sm"
        isOpen={confirmModal}
        body={<CustomConfirmModalContent onSendSuccess={(val) => getConfirmModalValue(val)} />}
        onClose={() => null}
      />

      <Modal
        isOpen={sendMessageModal}
        body={(
          <SendMessageModalContent
            imageUrl={cvDetail?.imgUrl ? cvDetail.imgUrl : ''}
            candidateName={cvDetail?.privateInformation?.name ? cvDetail.privateInformation.name : ''}
            onSendSuccess={() => setSendMessageModal(false)}
            onClose={() => {
              setSendMessageModal(false);
            }}
            slug={slug}
            trackingId={rid}
            locale={locale}
          />
        )}
        onClose={() => {
          setSendMessageModal(false);
        }}
      />

      {/* Available operations */}
      {availableOperations !== undefined && availableOperations?.length > 0
        && (
          <section
            className={`${isSinglePage ? 'mb-16 md:mb-4' : ' mb-4'} fixed bottom-0 left-1/2 z-[11] -translate-x-1/2 rounded-full bg-surface-100 p-2 shadow-[0_35px_60px_25px_rgba(0,0,0,0.3)]`}
          >
            <div className="flex items-center justify-center">

              {isLoadingStatusChange && (
                <div className="flex items-center">
                  <div className="inline-flex min-w-10
                                cursor-pointer items-center justify-center
                                rounded-full bg-slate-200 px-4.5
                                py-3 text-md font-medium transition-all duration-200
                                hover:shadow-md  disabled:opacity-60"
                  >
                    <TailwindSpinner className="!mx-0 size-5" />
                  </div>
                </div>
              )}

              {/* Left buttons for resume operations (rejected related) */}
              {availableOperationsButtons(true)}

              {/* Contact dropdown -  call, message, view */}
              <Dropdown>
                {/* button to view options */}
                <Dropdown.Button
                  as={Button}
                  color="secondary"
                  rounding="full"
                  className="mx-1 h-12 min-w-32 !text-md hover:!bg-secondary/90 lg:min-w-87.5"
                  disabled={isLoadingCallNow || isLoadingSendMessage}
                >
                  {isLoadingCallNow || isLoadingSendMessage ? <TailwindSpinner /> : (
                    <>
                      <div>
                        <PhoneIcon className="mr-2 size-5" />
                      </div>
                      {t('cv.detail.action.contact.label')}

                      <div className="absolute -bottom-2 flex flex-col items-center">
                        {returnUserStatus()}
                      </div>
                    </>
                  )}
                </Dropdown.Button>

                {/* Dropdown list */}
                <Dropdown.Items
                  animation="slideUp"
                  className="bottom-full mb-2 flex min-w-48 flex-col items-start gap-3 !rounded-md border-t-8 border-secondary p-4"
                >
                  {/* Direct call - only for online users */}


                  <div
                    className="absolute -top-3.5 left-1/2 min-w-max -translate-x-1/2 whitespace-nowrap rounded-lg bg-secondary px-2 py-0.5 text-pico font-semibold text-surface"
                  >
                    {isFreeCvInfoUnblock
                      ? <span>{t('cv.detail.action.contact.free')}</span>
                      : <span>{t('cv.detail.action.contact.with.10.neuro')}</span>}
                  </div>


                  <Dropdown.Item
                    disabled={!cvDetail?.online}
                    as={Button}
                    styling="text"
                    color={cvDetail?.online ? 'ink' : 'light'}
                    onClick={(event) => onViewContactDataPressed(event, true)}
                  >
                    <PhoneIcon className="mr-2 size-4" />
                    <span className="">
                      {t('cv.detail.action.call_direct.label')}
                    </span>
                  </Dropdown.Item>


                  {/* Send message */}
                  <Dropdown.Item as={Button} styling="text" color="ink" onClick={unlockWithMessage}>
                    <ChatBubbleLeftEllipsisIcon className="mr-2 size-4" />
                    <span className="flex items-center">
                      {t('cv.detail.action.message.label')}
                      {cvDetail?.hasUnreadMessage && (
                        <span className="ml-2 block size-2.5 rounded-full bg-error" />
                      )}
                    </span>
                  </Dropdown.Item>

                  {/* View contact data */}
                  <Dropdown.Item as={Button} styling="text" color="ink" onClick={onViewContactDataPressed}>
                    <div>
                      <AddressBookSolidIcon className="mr-2.5 h-4 w-3.5 fill-ink" />
                    </div>
                    <span className="">
                      {t('cv.detail.action.see_contact.label')}
                    </span>
                  </Dropdown.Item>
                </Dropdown.Items>
              </Dropdown>

              {/* Right buttons for resume operations (other than rejected) */}
              {availableOperationsButtons(false)}

            </div>
          </section>
        )}

      {/* Profile navigation and language selector - sticky only in modal */}
      <div
        className={`border-y bg-surface-100 py-2 transition-transform duration-300 ${
          closeCvModal ? 'sticky z-1 self-start' : ''
        } ${(withNavbarInModal || isSinglePage) ? ' sticky top-stickUnderHeader z-1' : ' sticky top-0 z-1 '}`}
      >
        <div className="container mx-auto">
          <div className="grid grid-cols-2 gap-4">
            <div className="flex items-center justify-start gap-1.5 md:gap-4">

              { /* Close modal button */
                closeCvModal && (
                  <Tooltip tooltipText={t('global.btn.close')} position="bottom" colorScheme="default">
                    <Button styling="none" onClick={closeCvModal} aria-label={t('global.btn.close')}>
                      <span className="flex size-7 items-center justify-center rounded-full bg-surface md:size-8">
                        <XMarkIcon className="size-5 text-ink" />
                      </span>
                    </Button>
                  </Tooltip>
                )
              }

              {/* Share CV button */}
              <Tooltip tooltipText={t('cv.action.share')} position="bottom" colorScheme="default">
                <Button
                  styling="none"
                  aria-label={t('cv.action.share')}
                  onClick={toggleShareModal}
                  className="flex size-7 items-center justify-center rounded-full bg-surface md:size-8"
                >
                  <ShareIcon className="size-4 text-ink" />
                </Button>
              </Tooltip>

              {/* Download CV button */}
              {isCompany && cvDetail?.id !== userId && (
                <Tooltip tooltipText={t('global.action.download.label')} position="bottom" colorScheme="default">
                  <Button
                    isLoading={isLoadingDownload}
                    styling="none"
                    aria-label={t('global.action.download.label')}
                    onClick={checkUnlockPdf}
                    className="flex size-7 items-center justify-center rounded-full bg-surface md:size-8"
                    spinnerClass="h-5 w-5"
                  >
                    {!isLoadingDownload && <ArrowDownTrayIcon className="size-5 text-ink" />}
                  </Button>
                </Tooltip>
              )}
            </div>

            {/* This component return the arrows to the navigation between items. While we do not have the list in Next.js, we are storing in cookie */}
            {/* the value of the last search (filters), then in react we are getting a list of CV-s for that search. We are sending also the offset */}
            {/* and then manipulate each case in the component */}

            <ResumeArrowsNavigation
              setLoadingCvStatusFromOperation={setLoadingCvStatusFromOperation}
              jobSlug={jobSlug}
              isFromJobContext={isFromJobContext}
              slug={slug}
            />

          </div>
        </div>
      </div>
    </>
  );
};
