'use client';

// Imports
// *******************************
import { useRef } from 'react';
import { useTranslation } from 'next-i18next';
import { Modal, Button } from '@components/common';
import { ModalSize } from '@type/modal-types';
import { SocialMediaShareList, SocialMediaShareListProps } from '@components/common/Elements/SocialMediaShareList';
import { useCopyToClipboard } from '@hooks/index';
import { HandleCheckboxChangeFunction } from '@type/hooks-functions/common';
import { CvWorthShare } from '../partials/CvWorthShare';


// Modal Share interface
// *******************************
interface ShareModalProps extends Omit<SocialMediaShareListProps, 'shareUrl'> {
  onClose: () => void,
  isOpen: boolean,
  shareUrl?: string,
  pathnameShareUrl?: string,
  modalSize?: ModalSize,
  cvWorthHandleCheckbox?: HandleCheckboxChangeFunction,
  copyLinkText?: string,
  referralCode?: string,
}


/**
 * @description Global share modal.
 * Add your custom cases (ie: Cv worth salary checkbox) as you need.
 * ** If you don't pass a shareUrl, the current window url will be used.**
 *
 * -----
 * Component Props:
 * * **onClose** - required: function to close the modal
 * * **isOpen** - required: boolean for opened/closed modal
 * * **shareUrl** - optional: string for share url; if not passed, the current window url will be used
 * * **pathnameShareUrl** - optional: string for pathname share url, to be appended to the current domain; if not passed, the current window url will be used
 * * **hashtag** - optional: object for social media hashtags; check SocialMediaShareListProps for more details
 * * **cvWorthHandleCheckbox** - optional: function for handling checkbox change
 * * **modalSize** - optional: string for modal size
 * * **ogTitle** - required: string for open graph title
 * * **ogDescription** - required: string for open graph description
 * * **copyLinkText** - optional: a custom message to display above the share link. If not provided, the default message ('share.copy-link') will be used.
 *
 * @example
 * // import components
 * import { useBjModal } from '@hooks/common/useBjModal';
 * import { ShareModal } from '@components/features/modals/share-modal/ShareModal';
 *
 * // use modal hook
 * const { isOpen, toggleModal } = useBjModal();
 *
 * // render components
 * <Button onClick={toggleModal}>Open share modal</Button>
 * <ShareModal
 *  isOpen={isOpen}
 *  onClose={toggleModal}
 *  shareUrl="https://www.example.com"
 *  ogTitle="Example title"
 *  ogDescription="Example description"
 *  hashtag={{ twitter: 'example', linkedin: 'example' }}
 *  cvWorthHandleCheckbox={handleCheckboxChange}
 *  modalSize="sm"
 * />
 *
 */
export const ShareModal = (props: ShareModalProps) => {
  // Props destructuring
  const {
    onClose,
    isOpen,
    shareUrl,
    pathnameShareUrl,
    hashtag,
    cvWorthHandleCheckbox,
    ogTitle,
    ogDescription,
    modalSize = 'sm',
    copyLinkText,
    referralCode,
  } = props;

  // If we get a pathnameShareUrl, we'll use the current domain and append the pathnameShareUrl.
  const currentDomain = typeof window !== 'undefined' ? window.location.origin : '';
  const shareUrlWithPartial = pathnameShareUrl ? `${currentDomain}${pathnameShareUrl}` : '';

  // If we don't get a shareUrl, we'll use the current url without the search/query parameters.
  let currentUrl = typeof window !== 'undefined' ? window.location.href.split('?')[0] : '';

  if (referralCode) {
    currentUrl += `?referral_code=${referralCode}`;
  }

  // Get the final share url
  const finalShareUrl = shareUrl || shareUrlWithPartial || currentUrl || '';

  // Ref for input element
  const inputEl = useRef<HTMLInputElement>(null);

  // Hook for copying text to clipboard
  const { handleCopyText } = useCopyToClipboard(finalShareUrl);

  // Translation
  const { t } = useTranslation('common');

  // Copy text from input field
  const copyInputText = () => {
    handleCopyText();
    if (inputEl.current != null) {
      inputEl.current.select();
    }
  };

  // Modal
  return (
    <Modal isOpen={isOpen} onClose={onClose} header={t('modal.title.share')} size={modalSize}>
      <div className="p-6">
        {/* Social media buttons */}
        <div className="flex flex-wrap justify-center gap-2.5">
          <SocialMediaShareList
            shareUrl={finalShareUrl}
            hashtag={hashtag}
            ogTitle={ogTitle}
            ogDescription={ogDescription}
          />
        </div>

        {/* Copy url block */}
        <p className="mt-4 text-center text-sm text-ink-medium">
          {copyLinkText || t('share.copy-link')}
        </p>
        <div className="mt-1.5 flex rounded-full border bg-surface-50 p-1">
          <input type="text" readOnly className="rounded-full !border-0 !shadow-none !outline-0 !ring-0" value={finalShareUrl} ref={inputEl} />
          <Button styling="text" className="mx-2 px-1 font-bold" onClick={copyInputText}>{t('share.copy-label')}</Button>
        </div>

        {/* CV WORTH CASE: Pick the public (without salary) or private (with salary) url for sharing */
          cvWorthHandleCheckbox && <CvWorthShare handleChange={cvWorthHandleCheckbox} label={t('share.cv-worth.include-salary')} />
        }

      </div>
    </Modal>
  );
};
