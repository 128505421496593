import { VideoCameraIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'next-i18next';
import { Tooltip } from '@components/common/Tooltip/GlobalTooltip';

export const VideoCvBadge = () => {
  // Translation
  const { t } = useTranslation('common');

  // Render component
  // *****************************************
  return (
    <span className="block absolute -bottom-2 -right-1 -left-1">
      <Tooltip
        tooltipText={t('video.cv.badge.tooltip')}
        position="bottom"
        className="block w-full bg-surface text-primary text-pico font-medium rounded-full py-0.5 shadow-xs"
        triggerContainerClass="flex items-center justify-center gap-1 whitespace-nowrap"
        contentSizeClass="max-w-48"
        floating
      >
        <VideoCameraIcon className="w-3.5 h-3.5 shrink-0" />
        <span className="inline-block pt-0.5 shrink-0 uppercase">
          {t('profile.similar.has.video.cv')}
        </span>
      </Tooltip>
    </span>
  );
};
