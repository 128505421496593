import fileImageIcon from '@images/icons/file-pdf.png';
import Link from 'next/link';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { ImageBj } from '@components/common';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { CvDetailObject } from '../interface/CvDetailInterface';

export const ProfileAttachments = ({ cvDetail }: { cvDetail: CvDetailObject }) => {
  const { t } = useTranslation('common');

  const DEFAULT_ATTACHMENTS = 3;
  const [numberOfAttachments, setNumberOfAttachments] = useState<number>(DEFAULT_ATTACHMENTS);

  if (!cvDetail?.privateInformation?.attachments || cvDetail.privateInformation?.attachments?.length === 0) {
    return null;
  }

  return (
    <section>
      <div className="my-3 flex flex-col gap-2">
        {cvDetail.privateInformation.attachments.slice(0, numberOfAttachments).map((item) => (
          <div key={item.id} className="flex items-center justify-between rounded-full bg-surface-100 px-4 py-2">
            <div className="flex items-center gap-2">
              <ImageBj src={fileImageIcon} alt="File" className="mr-3" width={20} height={26} />
              <div className="block text-xs font-normal">
                {item?.name?.length > 25 ? `${item?.name?.substring(0, 25)}...` : item?.name || ''}
              </div>
            </div>
            <div>
              <Link href={item.downloadLink} target="_blank" prefetch={false}>
                <ArrowDownTrayIcon className="size-5 text-ink" />
              </Link>
            </div>
          </div>
        ))}

        {/* See More Button */}
        {numberOfAttachments === DEFAULT_ATTACHMENTS
          && cvDetail.privateInformation?.attachments?.length > DEFAULT_ATTACHMENTS && (
            <div
              className="my-2 flex cursor-pointer items-center"
              role="presentation"
              onClick={() => setNumberOfAttachments(cvDetail?.privateInformation?.attachments?.length ?? DEFAULT_ATTACHMENTS)}
            >
              <span className="mr-2 block min-w-fit text-sm text-primary">
                {t('global.btn.see_more')}
              </span>
              <ChevronDownIcon className="inline-block size-5 text-primary" />
            </div>
        )}

        {/* See Less Button */}
        {numberOfAttachments > DEFAULT_ATTACHMENTS && (
          <div
            className="my-2 flex cursor-pointer items-center"
            role="presentation"
            onClick={() => setNumberOfAttachments(DEFAULT_ATTACHMENTS)}
          >
            <span className="mr-2 block min-w-fit text-sm text-primary">
              {t('global.btn.see.less')}
            </span>
            <ChevronDownIcon className="inline-block size-5 rotate-180 text-primary" />
          </div>
        )}
      </div>
    </section>
  );
};
