// RESUME CHECK UNLOCK ********************************************************************************
// pathname: /v1/resume-operation/${userId}/check-unlock/${type}
// ****************************************************************************************************
import {
  ApiCheckUnlockPriceInfoOk,
  ApiResumeOperationJobPayload,
  useSwrMutationApi,
} from '@hooks/useSwrApi';
import { SwrToastsProps } from '@type/swr';

// Interface
// ********************************************
interface SwrResumeCheckUnlockProps {
  userSlug: string;
  locale: string;
  successCallback?: (response: ApiCheckUnlockPriceInfoOk) => void;
  toastsOptions?: SwrToastsProps,
}

/**
 * @description SWR mutation: Check if the user can unlock a talent. Prepares contact, download or message events by unlocking / creating job.
 *
 * * **userSlug**: string. The slug of the user to check.
 * * **locale**: string. Locale to use for the request.
 * * **successCallback**: function, optional.
 * * **toastsOptions**: SWR toasts options, optional.
 */
export const useSwrResumeCheckUnlockPrice = (props: SwrResumeCheckUnlockProps) => {
  // Props destructuring
  // ********************************************
  const {
    userSlug,
    locale,
    successCallback,
    toastsOptions,
  } = props;

  // SWR mutation
  // ********************************************
  const {
    data, error, isMutating, reset, trigger,
  } = useSwrMutationApi<ApiCheckUnlockPriceInfoOk, ApiResumeOperationJobPayload>({
    url: `/v2/resume-operation/${userSlug}/check-unlock/contact?action=check`,
    locale,
    fetcherConfig: {
      method: 'POST',
      stringifyPayload: true,
    },
    apiOptions: {
      onSuccess: (response) => {
        // Callback
        if (successCallback) successCallback(response);
      },
    },
    toastsOptions,
  });

  // Return data
  // ********************************************
  return {
    data,
    error,
    isMutating,
    reset,
    trigger,
  };
};
