import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useTranslation } from 'next-i18next';
import { Modal } from '@components/common/Modal/Modal';
import { OnboardForm } from '@components/templates/company/OnboardModal';
import { Button } from '@components/common/Forms';
import { useBjForm } from '@hooks/useBjForm';
import { useSwrCompanyOnboarding } from '@hooks/useSwrApi/endpoints/company/useSwrCompanyOnboarding';
import { TailwindSpinner } from '@components/common';
import { useEffect } from 'react';

interface ContinueOrderProps {
  locale: Locale,
  successCallback?: () => void
}

/**
 * @description Renders the cv unlock payment modal
 *
 * The modal can be opened anywhere in the app using the **showCvContactModal** function.
 */
export const AddCompanyInfoModal = NiceModal.create((props: ContinueOrderProps) => {
  const { locale, successCallback } = props;
  // Use a hook to manage the modal state
  const modal = useModal();

  // Close modal
  const closeModal = () => {
    void modal.hide();
  };

  // Translation
  const { t } = useTranslation('common');

  const { data: employerData, isLoading } = useSwrCompanyOnboarding(locale);

  const {
    record, handleSubmit, formStatus, isPosting, updateFormElements,
  } = useBjForm({
    submitURL: '/v1/company',
    submitMethod: 'POST',
  });

  useEffect(() => {
    if (formStatus.actionStatus === 'success') {
      void modal.hide();
      if (successCallback) successCallback();
    }
  }, [formStatus, modal, successCallback]);

  // Sometimes the employer data is not available when the form is rendered
  // so we need to update the form elements when the employer data is available
  useEffect(() => {
    if (employerData) updateFormElements();
  }, [employerData, updateFormElements]);

  // Render component
  // *****************************************
  return (
    <Modal
      isOpen={modal.visible}
      onClose={closeModal}
      size="lg"
      header={t('global.action.order')}
    >
      <Modal.Body className="!p-0">
        {(employerData && !isLoading) && (
          <form onSubmit={handleSubmit} noValidate>
            <div className="bg-surface-100 p-6">
              <OnboardForm
                customer={employerData as ApiSchema<'CompanyWrite'>}
                locale={locale}
                record={record}
              />
            </div>
            <div className="border-t bg-surface px-6 py-4 text-right">
              <Button
                size="sm"
                color="secondary"
                rounding="full"
                type="submit"
                className="!px-7"
                isLoading={isPosting}
                disabled={isPosting}
              >
                {t('order.choosePayment.cta')}
              </Button>
            </div>
          </form>
        )}
        {isLoading && (
          <TailwindSpinner className="size-4.5 text-ink-light" />
        )}
      </Modal.Body>
    </Modal>
  );
});


/**
 * @description Opens the cv unlock payment modal anywhere in the app
 *
 * @example
 * // import the function
 * import { showCvContactModal } from '@components/features/modals/employer/CvContactModal';
 * // usage
 * <Button onClick={showCvContactModal(shoppingCardId, locale)}>Show contact modal</Button>
 */
export const showAddCompanyInfoModal = (locale: Locale, successCallback?: () => void) => {
  void NiceModal.show(AddCompanyInfoModal, { locale, successCallback });
};
