import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useTranslation } from 'next-i18next';
import { Modal } from '@components/common/Modal/Modal';
import { Button } from '@components/common';
import { useAppSelector } from 'src/app';
import { route } from '@services/symfony';

export type CvViewLimitStatusCode = 801 | 802 | 803;

interface CvViewLimitExceededModalProps {
  message: CvViewLimitStatusCode;
  locale: Locale;
}

/**
 * @description Renders the cv view limit exceeded modal
 *
 * The modal can be opened anywhere in the app using the **showCvViewLimitExceededModal** function.
 */
export const CvViewLimitExceededModal = NiceModal.create((props: CvViewLimitExceededModalProps) => {
  const { message, locale } = props;

  const modal = useModal();

  // Translation
  const { t } = useTranslation('common');

  const texts = {
    801: {
      desc: t('error.view-limit.missing-profile'),
      buttonText: t('error.view-limit.missing-profile.cta'),
    },
    802: {
      desc: t('error.view-limit.missing-subscription'),
      buttonText: t('error.view-limit.missing-subscription.cta'),
    },
    803: {
      desc: t('error.view-limit.subscription-quota'),
      buttonText: t('error.view-limit.subscription-quota.cta'),
    },
  };

  // Add job is allowed only for not logged or company users. We need this to check if we need to switch account
  const headerInfo = useAppSelector((state) => state.user.headerInfo);
  const notTalent = !headerInfo || (headerInfo && headerInfo.role !== 'USER');

  const closeModal = () => {
    void modal.hide();
  };

  const handleButtonClick = () => {
    void modal.hide();

    let redirUrl = `/${locale}/premium`;

    if (!notTalent) {
      redirUrl = route('account_user_type', { next_url: `/${locale}/premium` });
    }
    // Using router.push() to navigate between 'app' and 'pages' routers can cause issues
    // due to different routing systems. We have this modal in cv/search (pages)
    // and company-profile -> alumni (app). Use window.location.href for a full reload
    // or consolidate routes to a single router to avoid inconsistencies.
    window.location.href = redirUrl;
  };

  // Render component
  // *****************************************
  return (
    <Modal
      isOpen={modal.visible}
      onClose={closeModal}
      size="sm"
    >
      <Modal.Body>
        <strong>
          {texts[message].desc}
        </strong>
      </Modal.Body>
      <Modal.Footer className="text-center">
        <Button
          color="secondary"
          onClick={handleButtonClick}
        >
          {texts[message].buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
});


/**
 * @description Opens the cv view limit modal
 *
 * @example
 * // import the function
 * import { showCvViewLimitExceededModal } from '@components/features/modals/employer/CvViewLimitExceeded';
 * // usage
 * <Button onClick={showCvViewLimitExceededModal()}>Show modal</Button>
 */
export const showCvViewLimitExceededModal = (message: CvViewLimitStatusCode, locale: Locale) => {
  void NiceModal.show(CvViewLimitExceededModal, { message, locale });
};
