import { useTranslation } from 'next-i18next';
import { CvDetailObject } from '@components/templates/cv/interface/CvDetailInterface';

export const TagCloud = (
  {
    cvDetail,
  }:
  { cvDetail: CvDetailObject ; },
) => {
  const { t } = useTranslation('common');

  const tagItemClass = 'text-sm mr-2';

  if (!cvDetail?.tagCloud?.length) return null;

  return (
    <section className="py-7">
      <h6 className="mb-4 mr-2 border-b border-dotted pb-4 text-lg font-semibold">
        {`${t('cv.section.label.tagCloud')}`}
      </h6>
      <div className="flex flex-wrap items-center justify-start">
        {cvDetail.tagCloud.map((item) => (
          <div
            key={item.escoId}
            className={`${tagItemClass}`}
          >
            {item.label}
          </div>
        ))}
      </div>
    </section>
  );
};
