import { createContext } from 'react';
import { ApiTalentPageV3Ok } from '@type/v1-api-types/talent-search';
import { ExtendedSWRInfiniteResponse } from '@type/swr';


// Context interface
type PickedSwrHookProps<DataType> = Partial<Pick<ExtendedSWRInfiniteResponse<DataType, unknown>, 'showLoadMore' | 'setSize' | 'size'>>;
type TalentListsContextType<DataType> = PickedSwrHookProps<DataType> & {
  swrTalentList?: DataType[],
  openTalentModal?: (userSlug: string, as?: string) => void,
};


/**
 * @description Context for talent lists. It is used to store the talent list and the function to load more talents.
 * With it, you can use the navigation (previous, next) in CV modal detail.
 *
 * - **swrTalentList**: ApiTalentPageV3Ok - is the SWR talent list fetched from the server. It might be different from
 * what you render in the UI (like insert some ads).
 * - **setSelectedCvSlug**: Dispatch<SetStateAction<string | undefined>> - is the function to set the selected CV slug
 * for display in a modal. It is used for navigation in the CV modal detail.
 * - **size**: number - is the size of the infinite list (get it from the SwrInfinite hook)
 * - **setSize**: Dispatch<SetStateAction<number>> - is the function to set the size of the infinite list (get it from the SwrInfinite hook)
 * - **showLoadMore**: boolean - is the flag to show the load more button (get it from the SwrInfinite hook).
 */
export const TalentListsContext = createContext<TalentListsContextType<ApiTalentPageV3Ok> | undefined>({
  swrTalentList: undefined,
  openTalentModal: undefined,
  size: undefined,
  setSize: undefined,
  showLoadMore: undefined,
});
