import { useCallback, useEffect, useState } from 'react';
import {
  ApiResumeDetail, fetcher, isApiV1Errors, isResumeDetailStatusOk,
} from '@utils/data-fetching';
import { CvDetailObject } from '@components/templates/cv/interface/CvDetailInterface';
import { bjToast } from '@components/common';
import { useTranslation } from 'next-i18next';
import { InterfaceJobFolderOptions } from '@components/templates/cv/sections/ProfileHeading';
import { NoSymbolIcon } from '@heroicons/react/24/solid';
import { useSwrResumeGetOperationJob } from '@hooks/useSwrApi/endpoints/resume-operations';
import {
  CvViewLimitStatusCode,
  showCvViewLimitExceededModal,
} from '@components/features/modals/employer/CvViewLimitExceeded';

interface IProps {
  slug?: string;
  jobSlug?: string;
  rid?: string;
  isCompany?: boolean;
  toFetchOperationJob?: boolean;
  locale: Locale;
  waitForSlug?: boolean;
}

/**
 *
 * @param slug - the slug of the cv
 * @param isCompany
 * @param toFetchOperationJob - if we want to fetch the operation job folder
 * @param rid - query param. can be used in both cases. with slug or jobSlug
 * @param jobSlug - used in job context. used with slug
 * @param locale - the current app locale.
 * @param waitForSlug - if we should wait for the slug to be available before fetching the data.
 */
export const useCvDetails = ({
  slug, isCompany, toFetchOperationJob, rid, jobSlug, locale, waitForSlug = false,
}: IProps) => {
  const { t } = useTranslation('common');

  const [cvDetail, setCvDetail] = useState<CvDetailObject>();
  const [isCvLoaded, setCvLoaded] = useState<boolean>(false);
  const [showBlurredCv, setShowBlurredCv] = useState<boolean>(false);
  const [cvCurrentJobUnlockFolder, setCvCurrentJobUnlockFolder] = useState<InterfaceJobFolderOptions>();
  const [phoneHighlight, setPhoneHighlight] = useState<boolean>(false);
  const [availableResumeInformations, setAvailableResumeInformations] = useState<CvDetailObject>();
  const [fetchOperationJobId, setFetchOperationJobId] = useState<number | undefined>(undefined);

  // By default, every cv detail has a folder job id, if we do operations on some folders like shortlist/interview
  // on some job folder from select we need to fetch data in that job context
  const [jobFolderInfo, setJobFolderInfo] = useState<InterfaceJobFolderOptions>({
    jobId: 0,
    jobSlug: '',
    title: '',
  });

  const applicantSeenContactDefault = typeof cvDetail?.application?.applicantSeenContact === 'boolean'
    ? cvDetail?.application?.applicantSeenContact : false;
  const [applicantSeenContactStatus, setApplicantSeenContactStatus] = useState<boolean>(applicantSeenContactDefault);

  const toastErrorMessage = t('global.status.401-unauthorized.message');

  // SWR Mutation - Get Operation Job
  // ************************************
  const { trigger: triggerFetchOperationJob, isMutating: isLoadingOperationJob } = useSwrResumeGetOperationJob({
    jobId: fetchOperationJobId,
    locale,
    successCallback: (response) => {
      setCvCurrentJobUnlockFolder(response);
      setJobFolderInfo(response);
    },
  });

  // Hook to fetch operation job
  // ************************************
  useEffect(() => {
    const getOperationJob = () => {
      if (isCompany && toFetchOperationJob) {
        void triggerFetchOperationJob();
      }
    };

    return () => getOperationJob();
  }, [toFetchOperationJob, fetchOperationJobId, triggerFetchOperationJob, isCompany]);

  // fetch resume detail
  const fetchResumeDetail = useCallback((onSuccessCallback?: (cv: CvDetailObject) => void) => {
    // create url based on the context
    let resumeFetchApiLink = `/v2/user-resume-detail/${slug ?? ''}`;
    if (rid && slug && !jobSlug) {
      resumeFetchApiLink = `/v2/user-resume-detail/${slug}?rid=${rid}`;
    }
    // job context
    if (jobSlug && slug) {
      resumeFetchApiLink = `/v2/job/${jobSlug}/user-resume-detail/${slug}`;
      if (rid) {
        resumeFetchApiLink = `/v2/job/${jobSlug}/user-resume-detail/${slug}?rid=${rid}`;
      }
    }

    // fetch the data
    fetcher<ApiResumeDetail>(resumeFetchApiLink, locale)
      .then((response) => {
        if (isResumeDetailStatusOk(response)) {
          setPhoneHighlight(false);
          if (response?.blurred) {
            setShowBlurredCv(true);
            setAvailableResumeInformations(response);
            setCvLoaded(true);
          } else {
            setShowBlurredCv(false);
            setCvDetail(response as CvDetailObject);
            if (isCompany) {
              if (response?.application?.jobId) {
                setFetchOperationJobId(response.application.jobId);
              } else if (response?.jobMatches?.length) {
                const matchedJob = response.jobMatches.find((match) => match.jobId);
                setFetchOperationJobId(matchedJob?.jobId ?? 0);
              } else {
                setFetchOperationJobId(0);
              }
            }
            setCvLoaded(true);
          }
          if (onSuccessCallback) {
            onSuccessCallback(response);
          }
        } else if (isApiV1Errors(response)) {
          setCvLoaded(false);
          const code = response?.errors?.[0]?.code;
          if (code !== undefined && [801, 802, 803].includes(code)) {
            showCvViewLimitExceededModal(code as CvViewLimitStatusCode, locale);
          } else {
            bjToast(toastErrorMessage, {
              icon: <NoSymbolIcon className="-mb-px inline-block size-7 text-lg text-error" />,
              className: 'border-l-3 border-error',
            });
          }
        }
      }).catch(() => {
      });
  }, [setFetchOperationJobId, locale, slug, jobSlug, rid, toastErrorMessage, isCompany]);

  // Populate the CV Detail
  useEffect(() => {
    if (waitForSlug && slug) fetchResumeDetail();
    if (!waitForSlug) fetchResumeDetail();
  }, [fetchResumeDetail, waitForSlug, slug]);

  useEffect(() => {
    const applicantSeenContactRestore = typeof cvDetail?.application?.applicantSeenContact === 'boolean'
      ? cvDetail?.application?.applicantSeenContact : false;
    setApplicantSeenContactStatus(applicantSeenContactRestore);
  }, [cvDetail]);

  return {
    cvDetail,
    fetchResumeDetail,
    showBlurredCv,
    isCvLoaded,
    setCvLoaded,
    availableResumeInformations,
    cvCurrentJobUnlockFolder,
    phoneHighlight,
    setPhoneHighlight,
    applicantSeenContactStatus,
    setApplicantSeenContactStatus,
    jobFolderInfo,
    setJobFolderInfo,
    fetchOperationJobId,
    setFetchOperationJobId,
    isLoadingOperationJob,
  };
};
