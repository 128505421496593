// SUBSCRIPTION ORDER *****************************************************************************
// pathname: /v1/subscription/order
// ************************************************************************************************
import {
  useSwrMutationApi, ApiPublicationOrderPost, ApiPublicationOrderOk,
} from 'src/hooks/useSwrApi';


/**
 * @description SWR mutation: POST publication order
 * @param locale
 */
export const useSwrPublicationOrder = (locale = 'ro') => {
  // SWR mutation: fetch order
  // ********************************************
  const {
    data, error, isMutating, reset, trigger,
  } = useSwrMutationApi<ApiPublicationOrderOk, ApiPublicationOrderPost>({
    url: '/v2/publication/order',
    locale,
    fetcherConfig: {
      method: 'POST',
      stringifyPayload: true,
    },
  });


  // Return data
  // ********************************************
  return {
    data,
    error,
    isMutating,
    reset,
    trigger,
  };
};
