import { FormEvent, useMemo, useState } from 'react';
import {
  Avatar, bjToast, Button, LinkStyled,
} from '@components/common';
import { useTranslation } from 'next-i18next';
import { ChevronRightIcon, XMarkIcon, PaperAirplaneIcon } from '@heroicons/react/24/solid';
import { useSwrMessageSend } from '@hooks/useSwrApi/endpoints/messages';
import { useSwrConversationUser } from '@hooks/useSwrApi/endpoints';

interface IProps {
  slug: string;
  onSendSuccess: () => void;
  onClose: () => void;
  imageUrl?: string;
  candidateName: string;
  trackingId?: string;
  locale?: Locale;
}

/**
 * @description - Component for sending a message to a talent. It shows the message history and the last message
 * @param slug - resume slug
 * @param onSendSuccess - callback for success
 * @param onClose - callback for close
 * @param imageUrl - image url
 * @param candidateName - candidate name
 * @param trackingId - optional. The tracking id for the event.
 * @param locale - The current app locale.
 */
export const SendMessageModalContent = ({
  slug, onSendSuccess, onClose, imageUrl, candidateName, trackingId, locale,
} : IProps) => {
  const { t } = useTranslation('common');

  const [message, setMessage] = useState<string>('');

  // SWR: User conversations - for showing last message
  const { data: messageHistory } = useSwrConversationUser({
    locale: locale || 'ro',
    userSlug: slug,
    shouldFetch: !!slug,
  });

  // SWR: Send message
  const { trigger: triggerMessage, isMutating: isMutatingMessage } = useSwrMessageSend({
    slug,
    locale: locale || 'ro',
    trackingId,
    successCallback: () => {
      bjToast.success(t('messages-send-success'));
      onSendSuccess();
    },
  });


  // Applied to section - job titles
  const appliedToSection = useMemo(() => {
    const jobTitles = messageHistory?.applications?.map((item) => item.jobTitle) || [];
    const jobTitlesString = jobTitles.join(', ');

    return (
      <div className="text-sm font-normal">
        <span>
          {t('global.label.applied.to')}
          {' '}
          {jobTitlesString}
        </span>
      </div>
    );
  }, [messageHistory?.applications, t]);

  const handleContentEditableChange = (e: FormEvent<HTMLDivElement>) => {
    const value = e.currentTarget.textContent || '';
    setMessage(value);
  };

  return (
    <>
      <div className="p-3">
        <div className="flex items-start justify-between border-b px-3 py-2 font-bold lg:px-6 lg:py-4 ">
          <div className="flex items-center justify-center">
            {imageUrl
                  && (
                  <div className="min-w-20">

                    <Avatar
                      name={candidateName}
                      imgPath={imageUrl}
                      aspectRatio="square-uncertain"
                      extractImageName
                      alt={candidateName}
                    />

                  </div>
                  )}
            <div className="ml-2">
              {candidateName && <h3>{candidateName}</h3>}

              {messageHistory?.applications?.length && appliedToSection}

              {messageHistory?.lastMessage?.conversationId
                    && (
                    <LinkStyled
                      href={`/messages?id=${messageHistory?.lastMessage?.conversationId}`}
                      styling="none"
                      className="flex items-center text-sm font-normal text-primary"
                    >
                      {t('global.label.see.all-conversation')}
                      <ChevronRightIcon className="ml-1.5 size-3 text-primary" />

                    </LinkStyled>
                    )}
            </div>
          </div>
          <Button color="light" size="sm" className="-mr-2 ml-3 !rounded-full !p-2" onClick={onClose}>
            <XMarkIcon className="size-5" />
          </Button>
        </div>


        {messageHistory?.lastMessage?.body
              && (
              <div className={`${messageHistory?.lastMessage?.sent ? 'rounded-b-2xl rounded-tl-2xl' : 'rounded-r-2xl rounded-bl-2xl'} my-5 flex bg-surface-100 px-2  py-4`}>
                <div>
                  <span className="mb-1">{messageHistory?.lastMessage?.body}</span>
                </div>
              </div>
              )}

        <div className="mt-4 flex flex-row items-center justify-between rounded-4xl border border-input px-2 py-1">

          <span
            onInput={handleContentEditableChange}
            className="block min-h-full w-full overflow-hidden border-0 px-3 py-1  shadow-none outline-none focus:ring-0 active:border-0 active:ring-0 "
            role="textbox"
            contentEditable
            aria-label={t('cv.unblock.send.message.placeholder')}
          />

          <Button
            type="submit"
            color="secondary"
            rounding="full"
            className="py-4"
            disabled={message?.length === 0}
            isLoading={isMutatingMessage}
            onClick={() => {
              void triggerMessage({ body: message });
            }}
            aria-label={t('global.action.send')}
          >
            <PaperAirplaneIcon className="size-5 -rotate-45 text-white" />
          </Button>
        </div>
      </div>

    </>
  );
};

