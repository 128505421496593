import { fetcher } from '@utils/data-fetching';
import { useCallback, useEffect, useState } from 'react';
import { Video } from '@components/common/Video/Video';

export const VideoResumeModal = (props : { getDataFromUrl: string; locale: Locale }) => {
  const { getDataFromUrl, locale } = props;
  const [url, setUrl] = useState<string>('');


  const getResumeVideoContent = useCallback(() => {
    fetcher<string>(`${getDataFromUrl}`, locale, {
      method: 'GET',
    }).then((response) => {
      setUrl(response as string);
    }).catch(() => {});
  }, [locale, getDataFromUrl]);


  useEffect(() => {
    getResumeVideoContent();
  }, [getResumeVideoContent]);

  return (
    <>
      <Video
        controls
        classNames="w-full"
        url={url}
      />
    </>
  );
};

