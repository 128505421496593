import { ApiUserRole } from '@type/v1-api-types/user';
import {
  Anchor, Avatar, bjToast, Button, LinkStyled,
} from '@components/common';
import { showSubscriptionsModal } from '@components/features/modals/employer/subscription/SubscriptionsModal';
import { EllipsisHorizontalIcon, LockClosedIcon, ArrowPathIcon } from '@heroicons/react/24/solid';
import { ProfileSimilarCandidates } from '@components/templates/cv/sections';
import { Trans, useTranslation } from 'next-i18next';
import { CvDetailObject } from '@components/templates/cv/interface/CvDetailInterface';
import { ApiUserTalentItem } from '@type/v1-api-types';
import { fetcher, isApiV1Errors } from '@utils/data-fetching';

function IconPremiumTalentMedal() {
  return (
    <svg className="size-8" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="32" height="32" rx="16" fill="#224D89" fillOpacity="0.1" />
      <path
        d="M14.9688 14.0938L12.8125 10.5C12.6562 10.2188 12.3125 10 11.9688 10H8.5C8.09375 10 7.84375 10.4688 8.0625 10.8125L11.5625 15.7812C12.4688 14.9062 13.6562 14.3125 14.9688 14.0938ZM23.4688 10H20C19.6562 10 19.3438 10.1875 19.1562 10.5L17 14.0938C18.3125 14.3125 19.5 14.9062 20.4062 15.7812L23.9062 10.8125C24.125 10.4688 23.875 10 23.4688 10ZM16 15C12.9375 15 10.5 17.4688 10.5 20.5C10.5 23.5625 12.9375 26 16 26C19.0312 26 21.5 23.5625 21.5 20.5C21.5 17.4688 19.0312 15 16 15ZM18.875 19.9375L17.6875 21.0938L17.9688 22.7188C18.0312 23 17.7188 23.2188 17.4375 23.0938L16 22.3125L14.5312 23.0938C14.25 23.2188 13.9375 23 14 22.7188L14.2812 21.0938L13.0938 19.9375C12.875 19.7188 13 19.375 13.2812 19.3125L14.9375 19.0938L15.6562 17.5938C15.7188 17.4688 15.8438 17.4062 15.9688 17.4062C16.125 17.4062 16.25 17.4688 16.3125 17.5938L17.0312 19.0938L18.6875 19.3125C18.9688 19.375 19.0938 19.7188 18.875 19.9375Z"
        fill="#224D89"
      />
    </svg>
  );
}

interface IProps {
  availableResumeInformations: CvDetailObject;
  candidates?: ApiUserTalentItem[];
  slug?: string;
  forceMobileView?: boolean;
  jobSlug?: string;
  setUnlockFromBlurred?: () => void;
  isAuthenticated?: boolean;
  userRole?: ApiUserRole;
  locale: Locale;
}


/**
 * @description Blurred CV content. Used in the Talent Search context.
 * - **availableResumeInformations** - CvDetailObject - The CV details.
 * - **candidates** - ApiSimilarCandidateOk - The similar candidates.
 * - **slug** - string - The CV slug.
 * - **forceMobileView** - boolean - If the view should be forced to mobile.
 * - **jobSlug** - string - The job slug.
 * - **setUnlockFromBlurred** - function - Function to unlock the blurred CV.
 * - **isAuthenticated** - boolean - If the user is authenticated.
 * - **userRole** - ApiUserRole - The user role for authenticated users.
 * - **locale** - Locale - The current app locale.
 */
export const BlurredCvContent = (props: IProps) => {
  // Destructure props
  const {
    availableResumeInformations,
    candidates,
    slug,
    forceMobileView = false,
    jobSlug = '',
    setUnlockFromBlurred,
    isAuthenticated,
    userRole,
    locale,
  } = props;

  // Translation
  const { t } = useTranslation('common');


  // Activate contact package
  // ************************************
  const activateContactPackage = () => {
    fetcher(`/v2/publication/activate-contact-package/${jobSlug}`, locale, {
      method: 'POST',
      payload: {},
      stringifyPayload: true,
    }).then((response) => {
      if (isApiV1Errors(response)) {
        bjToast.error(response.errors[0].message);
      } else {
        const successMessage = (
          <Trans
            i18nKey="cv.detail.activate-contact-package.success"
            t={t}
            components={{
              component: <LinkStyled href="/wallet/transactions" styling="none" className="underline" />,
            }}
          />
        );
        bjToast.success(successMessage);
        if (setUnlockFromBlurred) setUnlockFromBlurred();
      }
    }).catch(() => {
    });
  };


  // Helper variables
  // ************************************
  const isPremium = availableResumeInformations?.promo === 'premium';
  const isCompany = isAuthenticated && userRole === 'COMPANY';
  const isTalent = isAuthenticated && userRole === 'USER';


  // Render component
  // ************************************
  return (
    <div className={`bg-surface ${forceMobileView ? '' : 'md:bg-surface-50'}`}>
      <div className={`${forceMobileView ? '' : 'container mx-auto'}`}>

        <div className={`block grid-cols-3 gap-4 ${forceMobileView ? '' : 'md:grid'}`}>

          <div
            className={`half-background-on-cv-detail relative col-span-2 border-0 bg-surface px-2 ${forceMobileView ? '' : 'md:min-h-full md:border-r md:pb-24 md:pr-2'}`}
          >

            <div className="mr-2 mt-10 flex items-center space-x-4 border-b-2 pb-10 pt-2">
              <div className={`flex flex-wrap items-center ${forceMobileView ? '' : 'md:whitespace-nowrap'}`}>

                {availableResumeInformations?.imgUrl && (
                  <Avatar
                    size="xl"
                    imgPath={availableResumeInformations.imgUrl}
                    imgFolder="resume_image"
                    extractImageName
                    imgPriority
                    withRing
                    alt="Profile picture"
                    className="mx-auto !outline-2 !outline-offset-3 !outline-secondary !ring-4 md:mx-0"
                  />
                )}

                {!availableResumeInformations?.imgUrl
                  && <div className="mx-auto mb-5 size-40 animate-pulse rounded-full bg-slate-200 md:mb-0 md:mr-2" />}

                <div className={`mt-1 max-w-md px-2 ${forceMobileView ? '' : 'md:px-8'}`}>
                  {availableResumeInformations?.activityStatus !== undefined
                    && (
                      <div className="mb-5 flex items-center text-ink-medium">
                        <span className="mr-2 block size-2.5 rounded-full bg-secondary" />
                        {t('cv.detail.active_user')}
                      </div>
                    )}

                  <h3 className="text-base font-bold">{t('cv.detail.profile.heading.hello.title')}</h3>
                  <h2 className="mb-5 text-2xl font-bold">
                    {availableResumeInformations?.leadExperience && (
                      <div>
                        <div className="text-md font-bold">{availableResumeInformations.leadExperience.title}</div>
                        <p
                          className="block text-sm font-normal"
                        >
                          {availableResumeInformations.leadExperience.company}
                        </p>
                      </div>
                    )}

                    {!availableResumeInformations?.leadExperience && <>{t('react.cv.placeholder.heading.candidate.name')}</>}
                  </h2>

                  <div className="mt-2 flex items-center">
                    <div>
                      <span className="block text-sm font-normal">
                        {availableResumeInformations?.location && `${availableResumeInformations.location}, `}
                        {availableResumeInformations?.age
                          && (
                            <>
                              {`${availableResumeInformations.age} ${t('cv.card.label.years')}`}
                            </>
                          )}
                        {availableResumeInformations?.gender && (
                          <>
                            {`, ${availableResumeInformations.gender}`}
                          </>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Show need authentication section */
              !isAuthenticated && (
                <div className="border-b py-3">
                  <Anchor
                    href="login"
                    symfonyRoute
                    size="sm"
                    color="secondary"
                    rounding="full"
                  >
                    <LockClosedIcon className="mr-1.5 size-4" />
                    {t('authenticate.to.view.cv')}
                  </Anchor>
                </div>
              )
            }

            {/* Show need company account type section */
              isTalent && (
                <div className="flex items-center gap-3 border-b bg-secondary/10 p-4">
                  <ArrowPathIcon className="size-7 font-medium text-secondary" />
                  {t('use.company.profile.to.view.cv')}
                </div>
              )
            }

            { /* Show need subscription section */
              isCompany && availableResumeInformations?.promo && ['premium', 'contact'].includes(availableResumeInformations.promo) && (
                <div
                  className={`my-4 flex flex-wrap items-center justify-between border-b px-4 pb-4 ${forceMobileView ? '' : 'md:flex-nowrap'}`}
                >
                  <div className="flex items-center">
                    {isPremium && (
                      <div className="w-10 min-w-10">
                        <IconPremiumTalentMedal />
                      </div>
                    )}

                    <div className="ml-2 pr-4">
                      <span className="block text-base">
                        {isPremium ? t('cv.detail.contact.type') : t('cv.detail.10-contact-package.title')}
                      </span>
                      <span className="block text-sm text-ink-medium">
                        {isPremium ? t('label-cv-view-activate-premium-contact') : t('cv.detail.10-contact-package.desc')}
                      </span>
                    </div>
                  </div>

                  { /* Open subscriptions modal */}
                  <Button
                    size="xs"
                    styling="solid"
                    rounding="full"
                    color="secondary"
                    className={`mb-2 mt-4 w-full shrink-0 ${forceMobileView ? '' : 'lg:my-0 lg:w-auto'}`}
                    onClick={isPremium ? showSubscriptionsModal : activateContactPackage}
                  >
                    {isPremium ? t('global.test.free.premium') : t('cv.detail.10-contact-package.cta')}
                  </Button>
                </div>
              )
            }


            {availableResumeInformations !== undefined && availableResumeInformations?.leadExperience && (
              <section className="border-b py-7">
                <h3 className="mb-2 text-xl font-bold">{t('cv.section.label.professional_experience')}</h3>
                <div className="relative z-10">
                  <div className="text-base text-ink-medium">
                    {availableResumeInformations?.leadExperience?.period !== undefined
                      && (
                        <span className="mb-3 block font-normal text-ink">
                          {t('label-total-experience')}
                          :
                          {` ${availableResumeInformations?.leadExperience?.period}`}
                        </span>
                      )}

                    {availableResumeInformations?.leadExperience?.company !== undefined
                      && (
                        <span
                          className="font-bold text-ink"
                        >
                          {`${availableResumeInformations?.leadExperience?.company} `}
                        </span>
                      )}
                    <div className="blur-sm">
                      {availableResumeInformations?.leadExperience?.location !== undefined && (
                        <span className="">{` ${availableResumeInformations?.leadExperience?.location}`}</span>
                      )}
                      <h3
                        className="my-2 text-2xl font-bold text-ink"
                      >
                        {availableResumeInformations?.leadExperience?.title}
                      </h3>
                      <div className="mb-3 h-4 w-full rounded bg-slate-200" />
                      <div className="mb-3 h-4 w-full rounded bg-slate-200" />
                      <div className="mb-3 h-4 w-full rounded bg-slate-200" />
                    </div>

                    { /* Open subscriptions modal */
                      isCompany && (
                        <div className="relative z-10 -mt-5 text-center">
                          <Button
                            size="md"
                            styling="text"
                            rounding="full"
                            className="my-3 min-w-fit text-center font-bold"
                            onClick={isPremium ? showSubscriptionsModal : activateContactPackage}
                          >
                            <EllipsisHorizontalIcon className="mr-2 size-7" />
                            {isPremium ? t('blurred.cv.subscription.choose.with.subscription') : t('blurred.cv.subscription.choose.with.contacts')}
                          </Button>
                        </div>
                      )
                    }

                  </div>
                </div>
              </section>
            )}

          </div>

          {
            candidates !== undefined && (
              <div className="col-span-1 hidden h-screen overflow-hidden pb-7 md:block md:px-4">
                <>
                  <div className="flex border-b ">
                    <div
                      className="min-w-fit cursor-pointer appearance-none p-4 text-sm font-bold text-ink outline-none focus:ring-0"
                    >
                      {t('label-cv-tab-comments')}
                    </div>
                    <div
                      className="min-w-fit cursor-pointer appearance-none border-b-2 border-secondary p-4 text-sm font-bold text-primary outline-none focus:ring-0"
                    >
                      {t('label-cv-tab-similar')}
                    </div>
                  </div>
                  {candidates?.length > 0 && (
                    <ProfileSimilarCandidates
                      locale={locale}
                      candidates={candidates}
                      userSlug={slug ?? ''}
                    />
                  )}

                  {
                    candidates?.length === 0 && (
                      <>
                        {[1, 2, 3].map((index) => (
                          <div key={index} className="mr-2 flex animate-pulse items-center space-x-4 border-b-2 py-2">
                            <div className="flex items-center">
                              <div className="mx-auto mb-5 size-16 rounded-full bg-slate-200 md:mb-0 md:mr-2" />
                              <div className="mx-auto mt-1 px-4">
                                <div className="mb-2 h-2 w-24 rounded bg-slate-200" />
                                <div className="mb-2 h-4 w-44 rounded bg-slate-200" />
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )
                  }
                </>
              </div>

            )
          }
        </div>
      </div>
    </div>
  );
};
