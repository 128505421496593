import { useTranslation } from 'next-i18next';
import { useSwrResumeTalentRatingAdd } from '@hooks/useSwrApi/endpoints/resume/useSwrResumeTalentRatingAdd';
import { bjToast, RatingWidget } from '@components/common';
import { useAuthClient } from '@hooks/common/useAuthClient/useAuthClient';
import { CvDetailInterface } from '../interface/CvDetailInterface';

interface IProps extends CvDetailInterface {
  locale: Locale,
  talentRating: number,
  canGiveRating: boolean,
  onSaveRating: (rating: number) => void;
}

/**
 * @description Component to render the comments section.
 * @param cvDetail - the cv detail object
 * @param jobSlug - the job slug
 * @param locale - the current app locale.
 */
export const ProfileTalentRating = ({
  cvDetail, locale, onSaveRating, talentRating, canGiveRating,
}: IProps) => {
  // Translation
  const { t } = useTranslation('common');

  // Helper variables
  const slug = cvDetail?.slug || '';
  const isLoggedInUserPrivateResume = cvDetail?.isOwnCV;

  // Authentication client side
  // ******************************************************
  const { isAuth, redirectToLogin } = useAuthClient();

  const { trigger, isMutating } = useSwrResumeTalentRatingAdd({
    slug,
    locale,
    successCallback: () => {
      bjToast.success(t('talent.rate.success'));
    },
    errorCallback: () => {
      bjToast.error(t('talent.rate.invalid-rating'));
    },
  });

  const onChangeStars = (value: number) => {
    if (isAuth) {
      onSaveRating(value);
      void trigger({ rating: value });
    } else {
      redirectToLogin(encodeURIComponent(`/${locale}/cv/${slug}`));
    }
  };

  if (isLoggedInUserPrivateResume) {
    return null;
  }

  return canGiveRating && (
    <div className="border-b-2 py-5">

      <div className="mb-3 flex items-center justify-between">
        <span className="text-md font-bold">{t('global.label.talent.evaluate')}</span>
      </div>

      <RatingWidget
        defaultValue={talentRating}
        className="max-w-1/3 justify-between border border-dashed bg-surface p-3 lg:max-w-full"
        onChange={(newValue) => onChangeStars(newValue)}
        disabled={isMutating || !canGiveRating}
      />
    </div>
  );
};
