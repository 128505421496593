// Company Landing ********************************************************************************
// pathname: /v1/company/onboarding
// ************************************************************************************************
import {
  useSwrApi,
} from 'src/hooks/useSwrApi';
import { route } from '@services/symfony';
import { useExtendedRouter } from '@hooks/next-routing-wrappers';


/**
 * @description SWR: fetch company data.
 * @param locale - { string } - the locale to use for the request. Default: 'ro'.
 */
export const useSwrCompanyOnboarding = (locale = 'ro') => {
  // Router
  const { router } = useExtendedRouter();

  // Get data from API
  // ********************************************
  const {
    data, error, isLoading, isValidating, mutate,
  } = useSwrApi<ApiSchema<'CompanyRead'>>({
    url: '/v1/company/onboarding',
    locale,
    apiOptions: {
      revalidateOnFocus: false,
      dedupingInterval: 1000 * 60, // 1 minute
      // redirect to login page if we get a 401
      onError: (newError) => {
        if (newError?.errors[0].code === 401) {
          void router.push(route('login'));
        }
      },
    },
    toastsOptions: {
      showErrorToast: true,
    },
  });


  // Return data
  // ********************************************
  return {
    data,
    error,
    mutate,
    isLoading,
    isValidating,
  };
};
