export const CvDetailTabsPlaceholder = () => (
  <>
    <div className="animate-pulse mr-2 border-b-2 py-5">
      <div className="mt-1">
        <div className="flex items-center gap-3 justify-between">
          <div className="bg-slate-200 h-4 w-1/2 mb-2 rounded" />
          <div className="bg-slate-200 h-4 w-1/2 mb-2 rounded" />
        </div>

      </div>
    </div>
    {[1, 2, 3].map((index) => (
      <div key={index} className="animate-pulse flex space-x-4 items-center mr-2 pt-2 pb-2 border-b-2">
        <div className="flex items-center">
          <div className="rounded-full mx-auto mb-5 md:mb-0 bg-slate-200 md:mr-2 w-16 h-16" />
          <div className="mt-1 mx-auto px-4">
            <div className="bg-slate-200 h-2 w-24 mb-2 rounded" />
            <div className="bg-slate-200 h-4 w-44 mb-2 rounded" />
          </div>
        </div>
      </div>
    ))}
  </>

);
