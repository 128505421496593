import { ApiUserResumeCommentOk } from '@type/v1-api-types/user';
import { Trans, useTranslation } from 'next-i18next';
import { useSwrResumeDeleteComment, useSwrResumeSetStatus } from '@hooks/useSwrApi/endpoints/resume-operations';
import { Button } from '@components/common';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import {
  CvDetailAvailableOperationsCurrentStatus,
  CvDetailAvailableOperationsParent,
} from '@components/templates/cv/interface/CvDetailInterface';
import { useSwrCache } from '@hooks/useSwrApi';
import { useEffect, useMemo } from 'react';
import { filterAndOrderAvailableOperations } from '@components/templates/cv/utils';
import { useAppDispatch } from '@store/index';
import { saveCommentUnlockResponse } from '@store/reducers/employer/resume/resumeReducer';
import { getOperationItemTextIcon } from '@components/templates/cv/utils/operations';


// Interface
// ******************************************************
interface CommentBlockProps {
  comment?: ApiUserResumeCommentOk;
  editedCommentSetter?: (data: ApiUserResumeCommentOk) => void;
  locale?: string;
  successCallback?: () => void;
  openModal?: () => void;
  jobSlug?: string;
  currentCVStatus?: CvDetailAvailableOperationsCurrentStatus;
}


/**
 * @description Component to render a comment block.
 * * **comment** - the comment object
 * * **locale** - the locale to use for the api calls. Defaults to 'ro'
 * * **successCallback** - callback to execute on success
 * * **openModal** - callback to open the modal
 * * **commentIdSetter** - callback to set the comment id
 * * **jobSlug** - the job slug
 * * **currentCVStatus** - the current CV status
 */
export const CommentBlock = (props: CommentBlockProps) => {
  // Destructure props
  const {
    comment,
    locale = 'ro',
    successCallback,
    openModal,
    editedCommentSetter,
    jobSlug,
    currentCVStatus,
  } = props;

  // Translation
  const { t } = useTranslation('common');

  const dispatch = useAppDispatch();

  // Helpers for comments operations
  const commentId = comment?.id || 0;
  const commentOperations = comment?.availableOperations;
  const canDelete = commentOperations && commentOperations?.find((action) => action.operationLink === 'openapi_delete_comment');
  const canEdit = commentOperations && commentOperations?.find((action) => action.operationLink === 'openapi_update_comment');
  // Get the CV status operation if exists
  const cvOperation = commentOperations && commentOperations?.find((action) => action.operationLink === 'openapi_set_cv_status_v2');
  // Get the user slug from the operation params
  const userSlug = cvOperation?.operationParams?.find((param) => param.id === 'userSlug')?.value || '';
  const operationStatus = Number(cvOperation?.operationParams?.find((param) => param.id === 'status')?.value || 0);

  // SWR: Delete comment
  // ******************************************************
  const {
    data: deletedOk,
    trigger: deleteComment,
    isMutating: isDeletingComment,
  } = useSwrResumeDeleteComment(locale, commentId, successCallback);


  // Handle edit comment
  // ******************************************************
  const handleEditComment = () => {
    if (comment && editedCommentSetter) editedCommentSetter(comment);
    if (openModal) openModal();
  };


  // SWR: Get available resume operations using SWR cache
  const { cachedData: availableResumeOperations, revalidateCache } = useSwrCache<CvDetailAvailableOperationsParent>({
    url: `/v1/available-resume-operations/${userSlug}?jobSlug=${jobSlug}`,
    locale,
  });

  // Revalidate cache on current cv status change
  useEffect(() => {
    if (currentCVStatus) {
      revalidateCache();
    }
  }, [currentCVStatus, revalidateCache]);


  // SWR: Set status
  const { trigger: setStatusTrigger, isMutating } = useSwrResumeSetStatus({
    userSlug,
    status: operationStatus,
    locale,
    successCallback: (response) => {
      // Save the response in the store to update the current cv status and the available operations
      dispatch(saveCommentUnlockResponse({
        response,
        operationName: cvOperation?.name || '',
        statusId: operationStatus,
      }));
    },
  });

  // Check if the set status button should be shown
  const canShowSetStatusButton = useMemo(() => {
    if (!cvOperation) {
      return false;
    }
    let availableOperations = availableResumeOperations?.length ? availableResumeOperations[0].availableOperations : [];
    availableOperations = filterAndOrderAvailableOperations(availableOperations);
    let canShow = false;
    availableOperations.forEach((operation) => {
      if (operation.operationId === cvOperation.operationId) {
        canShow = true;
      }
    });

    if (canShow) {
      return canShow;
    }

    if (!currentCVStatus) {
      return false;
    }

    // Special case if the operation status is to set Interview, and the current status is not Interview
    return cvOperation.operationId === 'status_3' && currentCVStatus?.id !== '3';
  }, [availableResumeOperations, cvOperation, currentCVStatus]);

  // Return null if no comment
  // ******************************************************
  if (!comment) return null;


  // Render component
  // ******************************************************
  return (
    <div className="py-4 border-b">

      {/* Comment header */}
      <div className="flex items-start gap-4">
        {/* Comment title */}
        <div className="grow">
          <div className="text-ink font-bold text-lg leading-snug">
            {comment?.ownerName}
            {comment?.ownerCompanyName ? ` - ${comment?.ownerCompanyName}` : ''}
          </div>
          <div className="text-ink-medium text-sm">
            {`${t('cv.action.comment.added.on')} ${comment?.createdAt}`}
          </div>
        </div>

        {/* Comment actions */}
        <div className="flex shrink-0 gap-x-3 mt-1 pt-px">
          { // edit comment; you cannot edit a comment without an id
            comment?.id && canEdit && (
              <Button
                color="light"
                size="xs"
                className="!px-1.5 !py-1 !border-input/20"
                onClick={handleEditComment}
              >
                <PencilIcon className="text-primary w-4 h-4" />
              </Button>
            )
          }
          { // delete comment; you cannot delete a comment without an id
            comment?.id && canDelete && (
              <Button
                color="light"
                size="xs"
                className="!px-1.5 !py-1 !border-input/20"
                spinnerClass="text-primary w-4 h-4"
                onClick={() => { void deleteComment({ data: commentId }); }}
                isLoading={isDeletingComment}
                disabled={isDeletingComment || deletedOk !== undefined}
              >
                {!isDeletingComment && <TrashIcon className="text-primary w-4 h-4" />}
              </Button>
            )
          }
        </div>
      </div>

      {/* Comment body */}
      <div className="text-base text-ink mt-2 whitespace-pre-wrap">
        {comment?.commentBody}
      </div>

      {/* Show set status button for the CV */}
      {
        comment?.id && cvOperation !== undefined && (
          <div className="flex gap-1">
            <div className="text-base text-ink whitespace-pre-wrap">
              <Trans
                i18nKey="cv.comment.bia.evaluation.cta"
                values={{
                  status: getOperationItemTextIcon(cvOperation, currentCVStatus!, t).text,
                }}
                components={{
                  customButton: (
                    <Button
                      styling="text"
                      size="md"
                      disabled={!canShowSetStatusButton || isMutating}
                      className="font-semibold"
                      onClick={() => {
                        void setStatusTrigger({
                          jobSlug,
                        });
                      }}
                    />
                  ),
                }}
              />
            </div>

          </div>
        )
      }

    </div>
  );
};
