import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { CvDetailObject } from '../interface/CvDetailInterface';

export const ProfileSkills = (
  {
    cvDetail,
    keyword = '',
  }:
  { cvDetail: CvDetailObject ;
    keyword: string },
) => {
  const { t } = useTranslation('common');
  const [skillsToShow, setSkillsToShow] = useState<number>(5);
  const [hasAlreadySeenAll, setHasAlreadySeenAll] = useState<boolean>(false);
  const [collapsedShowMore, setCollapsedShowMore] = useState<boolean>(false);

  const showMoreSkills = () => {
    setSkillsToShow(cvDetail?.skillsWithHighlight?.length as number);
    setCollapsedShowMore(true);
    setHasAlreadySeenAll(true);
  };

  const showLessSkills = () => {
    setSkillsToShow(5);
    setCollapsedShowMore(false);
    setHasAlreadySeenAll(true);
  };

  // show all the skills if the keyword is not in (skillsToShow state)
  if (cvDetail?.skillsWithHighlight && !hasAlreadySeenAll) {
    if (cvDetail.skillsWithHighlight.map((item: { name:string; }) => item?.name).indexOf(keyword) >= skillsToShow) {
      showMoreSkills();
    }
  }

  const skillClass = 'flex items-center text-sm text-ink py-1 px-3 rounded-full mr-2 cursor-pointer border border-surface-100 hover:bg-surface mb-2';

  return (
    <>
      {cvDetail?.skills
            && (
            <section className="py-7">
              <h6 className="mb-4 mr-2 border-b border-dotted pb-4 text-lg font-semibold">
                {`${t('cv.section.label.skills')} (${cvDetail?.skillsWithHighlight?.length})`}
              </h6>
              {/* check if skill is the keyword and highlight it */}
              <div className="flex flex-wrap items-center justify-start">
                {cvDetail?.skillsWithHighlight?.slice(0, skillsToShow).map((item: { name:string; highlight:boolean; isLanguage:boolean }) => (
                  <div
                    key={item.name}
                    className={`${skillClass}${item.isLanguage ? ' bg-transparent' : ''}${keyword === item.name ? ' bg-secondary/20' : ' bg-surface-100'}`}
                  >
                    {item.highlight
                        && <CheckIcon className="mr-2 size-5 text-secondary" />}
                    {item.name}
                  </div>
                ))}
              </div>

              {!collapsedShowMore && cvDetail?.skillsWithHighlight?.length > 5
                  && (
                  <div className="my-2 flex cursor-pointer items-center" role="presentation" onClick={showMoreSkills}>
                    <span className="mr-2 block text-sm text-primary">
                      {`${t('global.btn.see_more')} `}
                    </span>
                    <ChevronDownIcon className="inline-block size-5 text-primary" />
                  </div>
                  )}

              {collapsedShowMore
                  && (
                  <div className="my-2 flex cursor-pointer items-center" role="presentation" onClick={showLessSkills}>
                    <span className="mr-2 block text-sm text-primary">
                      {t('global.btn.see.less')}
                    </span>
                    <ChevronUpIcon className="inline-block size-5 text-primary" />
                  </div>
                  )}
            </section>
            )}
    </>
  );
};
