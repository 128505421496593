import { ApiUserResumeCommentOk } from '@type/v1-api-types/user';
import { useState } from 'react';
import { PlusIcon } from '@heroicons/react/24/solid';
import { Button, Modal } from '@components/common';
import { useTranslation } from 'next-i18next';
import { useSwrUserResumeComments } from '@hooks/useSwrApi/endpoints/user';
import { useSwrResumeAddComment } from '@hooks/useSwrApi/endpoints/resume-operations';
import { useBjModal } from '@hooks/common/useBjModal';
import { useAppSelector } from '@store/index';
import { EditCommentModalContent } from '../partials/modals/EditCommentModalContent';
import { CvDetailAvailableOperationsCurrentStatus, CvDetailInterface } from '../interface/CvDetailInterface';
import { CommentBlock } from '../partials/lists/CommentBlock';

interface IProps extends CvDetailInterface {
  locale: Locale,
  currentCVStatus?: CvDetailAvailableOperationsCurrentStatus,
  isCompany?: boolean,
}

/**
 * @description Component to render the comments section.
 * @param cvDetail - the cv detail object
 * @param jobSlug - the job slug
 * @param currentCVStatus - the current cv status
 * @param isCompany - whether the current user is in Company context.
 * @param locale - the current app locale.
 */
export const ProfileComments = ({
  cvDetail, jobSlug, currentCVStatus, isCompany, locale,
}: IProps) => {
  // Translation
  const { t } = useTranslation('common');

  // Helper variables
  const cvUserId = cvDetail?.id || 0;
  const userSlug = cvDetail?.slug || '';
  const isLoggedInUserPrivateResume = cvDetail?.isOwnCV;

  // States
  const [userComment, setUserComment] = useState<string>('');
  const [editedComment, setEditedComment] = useState<ApiUserResumeCommentOk>();


  // Modal
  // ******************************************************
  const { isOpen, openModal, closeModal } = useBjModal();

  const shouldFetch = Boolean(useAppSelector((state) => state?.user?.headerInfo));


  // SWR: Get user comments. Pass the cvDetail?.comments as fallback data.
  // If cvDetail?.comments is undefined, then auto-fetch the data.
  // ******************************************************
  const {
    data: commentsList,
    mutate: getCommentsList,
  } = useSwrUserResumeComments({
    locale, userSlug, serverSideData: cvDetail?.comments, shouldFetch,
  });


  // SWR: Add new comments
  // ******************************************************
  // On success, re-fetch the comments list and clear the textarea.
  const commentAddedSuccessfully = () => {
    void getCommentsList();
    setUserComment('');
  };

  // SWR: Add new comment
  const {
    trigger: addComment,
    isMutating: isAddingComment,
  } = useSwrResumeAddComment(locale, cvUserId, commentAddedSuccessfully);

  // Render component
  // ******************************************************
  return (
    <>
      <section className={`w-full py-4 ${isLoggedInUserPrivateResume ? 'pointer-events-none opacity-60' : ''}`}>

        { /* add new comment section */}
        <div className="border-b pb-4">
          <textarea
            className="mb-4 text-base disabled:cursor-not-allowed disabled:opacity-50"
            value={userComment}
            placeholder={t('cv.action.comment.add')}
            onChange={(e) => {
              setUserComment(e.target.value);
            }}
            rows={4}
            disabled={!isCompany}
          />

          <Button
            color="white"
            size="sm"
            rounding="full"
            className="w-full !py-2 !font-bold !text-primary"
            spinnerClass="-ml-1 mr-3 h-4.5 w-4.5"
            disabled={!userComment || isAddingComment || !isCompany}
            isLoading={isAddingComment}
            onClick={() => {
              void addComment({ comment: userComment });
            }}
          >
            {!isAddingComment && <PlusIcon className="mr-2 size-4.5 text-ink-medium" />}
            {t('cv.action.comment.add')}
          </Button>
        </div>

        {/* Remove BIA comments from comments list */}
        {
          (commentsList ?? [])
            .filter((comment) => comment.ownerName !== 'BIA')
            .map((item) => (
              <CommentBlock
                key={item.id}
                comment={item}
                locale={locale}
                successCallback={() => {
                  void getCommentsList();
                }}
                openModal={openModal}
                editedCommentSetter={setEditedComment}
                jobSlug={jobSlug}
                currentCVStatus={currentCVStatus}
              />
            ))
        }

      </section>

      {/* Edit comment modal */
        editedComment && (
          <Modal
            isOpen={isOpen}
            header={t('cv.action.comment.edit')}
            body={(
              <EditCommentModalContent
                editedComment={editedComment}
                locale={locale}
                successCallback={() => {
                  void getCommentsList();
                }}
                closeModalCallback={closeModal}
              />
            )}
            onClose={closeModal}
          />
        )
      }
    </>
  );
};
