import { NoSymbolIcon, StarIcon } from '@heroicons/react/24/outline';
import { ComponentProps } from 'react';
import { TFunction } from 'next-i18next';
import {
  CvDetailAvailableOperations,
  CvDetailAvailableOperationsCurrentStatus,
  CvDetailBaseOperation,
} from '../interface/CvDetailInterface';

/**
 * @description Filter by status operation and order available operations, putting REJECTED at the beginning.
 * @param data - list of available operations.
 */
export const filterAndOrderAvailableOperations = (data: CvDetailAvailableOperations[]) => data?.filter((x:{ type:string }) => x.type === 'status').reduce((prev, curr) => {
  // If the operation is REJECTED, add it to the beginning of the list
  if (curr.operationId === 'status_4') {
    return [curr, ...prev];
  } return [...prev, curr];
}, [] as CvDetailAvailableOperations[]);


// Hover classes by operation id
const hoverClassesByOperationId = {
  status_21: 'hover:bg-primary hover:text-surface',
  status_22: 'hover:bg-secondary hover:text-surface',
  status_3: 'hover:bg-pink hover:text-surface',
  status_4: 'hover:bg-error hover:text-surface',
  status_2: 'hover:bg-opacity-10',
};

/**
 * @description Get hover classes by operation.
 * @param operation
 */
export const getHoverClassesByOperation = (operation: CvDetailBaseOperation) => hoverClassesByOperationId[operation.operationId as keyof typeof hoverClassesByOperationId] ?? '';


/**
 * @description Get operation item text and icon.
 * @param operation
 * @param currentCVStatus
 * @param t - translation function
 */
export const getOperationItemTextIcon = (operation: CvDetailBaseOperation, currentCVStatus: CvDetailAvailableOperationsCurrentStatus, t: TFunction) => {
  // view operation and hired status
  if (operation.operationId === 'status_2' && currentCVStatus?.id === '22') {
    return {
      getIcon: (props: ComponentProps<typeof StarIcon>) => <StarIcon {...props} />,
      text: t('cv.detail.resume.candidate.status.hired'),
    };
  }

  //  view operation adn rejected status
  if (operation.operationId === 'status_2' && currentCVStatus?.id === '4') {
    return {
      getIcon: (props: ComponentProps<typeof NoSymbolIcon>) => <NoSymbolIcon {...props} />,
      text: t('cv.detail.resume.candidate.status.rejected'),
    };
  }

  // operation to reject
  if (operation.operationId === 'status_4') {
    return {
      getIcon: (props: ComponentProps<typeof NoSymbolIcon>) => <NoSymbolIcon {...props} />,
      text: t('reject.candidate'),
    };
  }

  // other operation
  return {
    getIcon: (props: ComponentProps<typeof StarIcon>) => <StarIcon {...props} />,
    text: operation.name,
  };
};
