import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid';
import { Anchor } from '@components/common';
import { useTranslation } from 'next-i18next';
import { ApiUserTalentItem } from '@type/v1-api-types';
import { TalentCard } from '@components/templates/employer/cards-lists/TalentCard';

interface ISimilarCandidates {
  candidates: ApiUserTalentItem[];
  userSlug: string;
  locale: Locale;
}

export const ProfileSimilarCandidates = (props: ISimilarCandidates) => {
  // Destructure props
  const {
    userSlug,
    candidates,
    locale,
  } = props;

  // Translation
  const { t } = useTranslation('common');

  if (!candidates?.length) {
    return (
      <div id="similarCandidates" className="rounded-md bg-surface p-10 shadow-md">{t('profile.similar.not_found')}</div>
    );
  }

  return (
    <>

      <section id="similarCandidates" className="mb-10">
        <h6 className="mb-3 text-md font-bold">{t('cv.detail.similar.candidates.text')}</h6>

        <div className="flex grid-cols-3 flex-col gap-5 md:grid">
          {candidates?.length > 0 && candidates.slice(0, 8).map((item: ApiUserTalentItem) => (
            <TalentCard cardData={item} locale={locale} key={item.slug} />
          ))}
        </div>


        {candidates?.length > 8 && (
          <Anchor
            href={`/${locale}/cv/${userSlug}/similar`}
            styling="text"
            className="mt-4 flex w-full cursor-pointer items-center justify-center rounded-xl bg-surface
            py-2 text-center text-sm font-bold text-primary"
            target="_blank"
          >
            <EllipsisHorizontalIcon width={16} className="mr-2 text-primary" />
            {t('cv.detail.similar.candidates.seeall.text')}
          </Anchor>
        )}
      </section>
    </>
  );
};
